<template>
    <v-btn-toggle
        v-model="state.current.annexBCG.type"
        tile
        group
    >
        <v-btn @click="income" value="IncomeStatement">
        Income Statement
        </v-btn>

        <v-btn @click="annexB" value="AnnexB">
        Annex B
        </v-btn>

        <v-btn @click="annexC" value="AnnexC">
        Annex C
        </v-btn>

        <v-btn @click="annexC" value="AnnexG">
        Annex G
        </v-btn>
    </v-btn-toggle>
</template>

<style lang="scss">
    @import "../../../style/variables.scss";
    @import "../../../style/mixins.scss";

    .v-btn {
        font-family: $primefont !important; 
        text-transform: initial;
        font-size: .9rem;
        font-weight: 500;
    }

</style>

<script>

export default {
    data() {
        return {
            state: this.$store.state,
            mHeader: [
                {
                    text: 'Particulars',
                    align: 'left',
                    sortable: false,
                    value: 'Title',
                    i: true, b: true, c: true, g: true,
                },
                {
                    text: 'Account Code',
                    align: 'left',
                    sortable: false,
                    value: 't2',
                    i: true, b: true, c: true, g: true,
                },
                {
                    text: 'Income Classification',
                    align: 'left',
                    sortable: false,
                    value: 't9',
                    i: false, b: true, c: false, g: false,
                },
                {
                    text: 'CY ' + (this.$store.state.current.budgetYear - 2),
                    align: 'left',
                    sortable: false,
                    value: 't10',
                    i: true, b: true, c: false, g: false,
                },
                {
                    text: 'CY ' + (this.$store.state.current.budgetYear - 1) + ' 1stSem',
                    align: 'left',
                    sortable: false,
                    value: 't11',
                    i: true, b: true, c: false, g: false,
                },
                {
                    text: 'CY ' + (this.$store.state.current.budgetYear - 1 ) + ' 2ndSem',
                    align: 'left',
                    sortable: false,
                    value: 't12',
                    i: true,
                    b: true,
                    c: false,
                    g: false,
                },
                {
                    text: 'Total',
                    align: 'left',
                    sortable: false,
                    value: 't17',
                    i: true,
                    b: true,
                    c: false,
                    g: false,
                },
                {
                    text: 'CY ' + (this.$store.state.current.budgetYear) ,
                    align: 'left',
                    sortable: false,
                    value: 't8',
                    i: true,
                    b: true,
                    c: true,
                    g: true,
                },
                {
                    text: 'Actions ',
                    align: 'right',
                    sortable: false,
                    value: 'action',
                    visible: false,
                    i: true,
                    b: true,
                    c: true,
                    g: true,
                },
            ],
        };
    },
    mounted() {
        this.$store.state.current.annexBCG.header = this.mHeader.filter((o) => o.i === true);
    },
    methods: {
        income() {
            return '';
        },
        annexB() {
            return '';
        },
        annexC() {
            return '';
        },
        annexG() {
            return '';
        },
    },
};
</script>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{staticClass:"mb-5"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.state.reportDetails.title)+" "+_vm._s(_vm.state.reportDetails.subtitle)+" ")]),_c('div',{staticClass:"barangay"},[_vm._v(" "+_vm._s(_vm.state.reportDetails.barangay)+" ")]),_c('div',{staticClass:"city"},[_vm._v(" "+_vm._s(_vm.state.reportDetails.city)+" ")])]),_c('v-data-table',{staticClass:"elevation-1 reportable",attrs:{"headers":_vm.getters.getHeadersAnnexBCG,"items":_vm.getters.annexBCG,"loading":_vm.state.loading,"dense":""},scopedSlots:_vm._u([{key:"item.Title",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"white--text fs"},[_vm._v(" "+_vm._s(_vm.indent(item.Lvl))+" ")]),_c('a',{staticClass:"fs"},[_vm._v(_vm._s(item.Title)+" ")]),_c('span',{staticClass:"fs"},[_vm._v(_vm._s(item.Subtitle)+" ")])]}},{key:"item.t2",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"fs"},[_vm._v(_vm._s(item.t2))])]}},{key:"item.t9",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"fs"},[_vm._v(_vm._s(item.t9))])]}},{key:"item.t10",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"fs"},[_vm._v(_vm._s(item.t10)+" ")]),_c('span',{staticClass:"fs"},[_c('strong',[_vm._v(_vm._s(item.t5))])])]}},{key:"item.t11",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"fs"},[_vm._v(" "+_vm._s(item.t11)+" ")]),_c('span',{staticClass:"fs"},[_c('strong',[_vm._v(_vm._s(item.t4))])])]}},{key:"item.t12",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"fs"},[_vm._v(" "+_vm._s(item.t12)+" ")]),_c('span',{staticClass:"fs"},[_c('strong',[_vm._v(_vm._s(item.t6))])])]}},{key:"item.t17",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"fs"},[_vm._v(" "+_vm._s(item.t17)+" ")]),_c('span',{staticClass:"fs"},[_c('strong',[_vm._v(_vm._s(item.t3))])])]}},{key:"item.t8",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"fs"},[_vm._v(" "+_vm._s(item.t8)+" ")]),_c('span',{staticClass:"fs"},[_c('strong',[_vm._v(_vm._s(item.t7))])])]}},(_vm.state.status.annexBCG===0)?{key:"item.action",fn:function(ref){
var item = ref.item;
return [(item.isValue === 'True')?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]):_vm._e(),(item.isValue === 'True')?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}}:null,{key:"top",fn:function(){return [_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.state.dialog.save),callback:function ($$v) {_vm.$set(_vm.state.dialog, "save", $$v)},expression:"state.dialog.save"}},[_c('Crud',{attrs:{"editModeCrud":_vm.editModeReport}})],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.state.dialog.delete),callback:function ($$v) {_vm.$set(_vm.state.dialog, "delete", $$v)},expression:"state.dialog.delete"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Delete ")]),_c('v-card-text',[_vm._v(" Continue removing the values of "+_vm._s(_vm.state.crudAT.search)+" ? ")]),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"deep-purple accent-4"},on:{"click":function($event){return _vm.deleteExecute()}}},[_vm._v(" Delete ")]),_c('v-btn',{attrs:{"text":"","color":"deep-purple accent-4"},on:{"click":function($event){_vm.state.dialog.delete = false}}},[_vm._v(" Cancel ")])],1)],1)],1)]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"base box1"},[_c('div',{staticClass:"base box2"},[_c('ul',{staticClass:"rowlines"},_vm._l((_vm.headers),function(item,i){return _c('li',{key:item.name},[_c('div',{class:{
                    headerbox: (i < (_vm.headers.length-1) ), 
                    lastheaderbox:(i == (_vm.headers.length-1)) 
                    },style:({ width: item.width + 'px'})},[_vm._v(" "+_vm._s(item.text)+" ")])])}),0)]),_c('div',{staticClass:"base box3",attrs:{"id":"boxid"}},[_c('div',{staticClass:"box4",style:({ top: this.position + 'px'})},_vm._l((_vm.state.AccountTitles),function(data,index){return _c('ul',{key:data.account_id,class:{
                        rowlines: true,
                        active: (index == _vm.mActiveIndex)
                    },attrs:{"dataid":data.account_id,"dataindex":index},on:{"click":function($event){return _vm.dataClicked({ind: index, id: data.account_id})}}},_vm._l((_vm.headers),function(item,i){return _c('li',{key:item.name},[_c('div',{class:{
                    box: (i < (_vm.headers.length-1) ), 
                    lastbox:(i == (_vm.headers.length-1)) 
                    },style:({ width: item.width + 'px'})},[_vm._v(" "+_vm._s(data[item.name])+" ")])])}),0)}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }
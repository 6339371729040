<template>

        <v-btn-toggle
          v-model="state.current.status"
          borderless
          class="mt-2"
        >
          <v-btn @click="statusUpdated(0)">
            <span class="hidden-sm-and-down">Ongoing</span>
            <v-icon right>mdi-run-fast</v-icon>
          </v-btn>

          <v-btn @click="statusUpdated(1)">
            <span class="hidden-sm-and-down">Completed</span>
            <v-icon color="orange" right>mdi-eye</v-icon>
          </v-btn>

          <v-btn @click="statusUpdated(2)">
            <span class="hidden-sm-and-down">Approved</span>
            <v-icon color="green" right>mdi-thumb-up</v-icon>
          </v-btn>

        </v-btn-toggle>
    
</template>

<script>
export default {
    data() {
        return {
            state: this.$store.state,
        };
    },
    methods: {
        statusUpdated(id) {

            if (this.state.current.header === 'AnnexA') {this.state.status.annexA = id; }
            if (this.state.current.header === 'AnnexD') {this.state.status.annexD = id; }
            if (this.state.current.header === 'AnnexE') {this.state.status.annexE = id; }
            if (this.state.current.header === 'AnnexF') {this.state.status.annexF = id; }

            const param = {
                parameters:
                {
                    key: this.$store.state.apiKey,
                    procedurename: 'spSet_AnnexStatus',
                    statusID: this.state.status.annexBCG,
                    header: this.state.current.header,
                    brgy_id:  this.state.current.brgyID,
                    budgetYear:  this.state.current.budgetYear,
                    budgetTypeId: this.state.current.budgetTypeID,
                },
            };
            this.$store.state.api.executePost(this.$store, param, this.state.current);
        },
    },
};
</script>
import { Vue } from 'vue-property-decorator';
import NodeCache from 'node-cache';
import findCacheDir from 'find-cache-dir';


export type VForm = Vue & { validate: () => boolean };

export class Api {
    public myCache = new NodeCache( { stdTTL: 100, checkperiod: 120 } );
    public async executeGet(store: any, urlparameter: string, source: any, force = false) {
        // console.log('force 0', force);
        const state = store.state;
        const baseUrl = store.state.baseUrl;
        const apiKey = store.state.apiKey;
        const url = baseUrl + '?key=' + apiKey;

        // console.log('api', urlparameter);
        // console.log('api/myKey', this.myCache.get( urlparameter ));
        let response = {
            data: { Header : ''},
        };

        const cachedData: any = this.myCache.get( urlparameter ) ;

        // console.log('force 1', force);
        if (!force) {
            if (cachedData === undefined) {
                force = true;
            } else {
                response = cachedData;
                const a = state.apiCalls.filter((o: any) => o.parameter === urlparameter)[0];
                if (a !== undefined) {
                    force = a.forceLoading;
                }
            }
        }
        // console.log('force 2', force);
        // console.log('force ver 2', force);
        // console.log('response', force);

        if (force) {
            // console.log('force call api');
            const promise = await state.axios.get(url + '&' + urlparameter);
            // console.log('success call api', promise);

            response.data = promise.data;
            this.myCache.set( urlparameter , response, 10000 );

            const apcData = {
                header: response.data.Header,
                parameter: urlparameter,
                forceLoading: false,
                brgyID:  state.current.brgyID.toString(),
                budgetYear: state.current.budgetYear.toString(),
                callCount: 0,
            };
            const d = state.apiCalls.filter((o: any) => o.parameter === urlparameter);



            apcData.callCount = d.length + 1;
            // console.log('apcData', apcData)
            state.apiCalls = state.apiCalls.filter((o: any) => o.parameter !== urlparameter);
            state.apiCalls.push(apcData);
            // console.log('state.apiCalls new', state.apiCalls)
        }
        // console.log('urlparameter', urlparameter);
        // console.log('source', source);
        // console.log('state.current', state.current);
        // console.log('response.data.Header', response.data.Header);
        this.excuteGetHeader(response.data.Header, response, store, state, source, response.data) ;
    }

    public async excuteGetHeader(header: any, response: any, store: any, state: any, source: any, repData: any) {
        switch (header) {
            case 'brgyuser.spUserVerification':
                // Login
                // console.log('hit');
                store.commit('spUserVerification', response.data[response.data.Header][0]);
                state.current.brgy_name = state.spUserVerification.brgyName;
                state.current.brgy_district = state.spUserVerification.district;
                state.current.brgyID = state.spUserVerification.brgy_ID;
                // console.log(state.spUserVerification.verificationStatus);
                if (state.spUserVerification.verificationStatus === 'new') {
                    state.router.push('/userverification');
                    return;
                }
                if (state.spUserVerification.verificationStatus === 'requested') {
                    state.router.push('/userverificationforapproval');
                    return;
                }
                if (state.spUserVerification.verificationStatus === 'approved') {
                    store.commit('userHasLoggedIn');
                    if (state.current.brgyID === '0') {
                        state.router.push('/barangayList/2020');
                    } else {
                        state.router.push('/annexbcg');
                    }

                    return;
                }
                break;
            case 'brgyuser.spGetBarangayList':
                // verification
                source.searhItems = response.data[response.data.Header].flatMap((o: any) => o.brgy_name);
                source.loading = false;
                store.commit('spGetBarangayList', response.data[response.data.Header]);
                return store.state.spGetBarangayList;
            case 'spGetBarangayList':
                // left nav
                source.searhItems = response.data[response.data.Header].flatMap((o: any) => o.brgy_name);
                source.loading = false;
                store.commit('spGetBarangayList', response.data[response.data.Header]);
                return store.state.spGetBarangayList;
            case 'spUploadRequest':
                const segmentParam = 'procedurename=spUploadSegment&requestID=' +
                    repData[header][0].requestID +
                    '&segmentIndex=' + 1 + '&segmentData=' + source.data.substring(0, source.baseLen);
                state.api.executeGet(store, segmentParam, source);
                break;
            case 'spUploadSegment':
                // console.log(repData[header][0]);
                if (repData[header][0].status === 'incomplete') {
                    source.progress = source.unit * (Number(repData[header][0].totalSegment) + 1);
                    state.api.executeGet(
                        store,
                        'procedurename=spUploadSegment&requestID=' + repData[header][0].requestID +
                        '&segmentIndex=' + Number(repData[header][0].totalSegment) + 1 +
                        '&segmentData=' + source.data.substring(
                            (source.baseLen * (Number(repData[header][0].totalSegment))) + 1,
                            source.baseLen * (Number(repData[header][0].totalSegment) + 1)),
                        source);
                }
                if (repData[header][0].status === 'complete') {
                    source.progress = 0;
                    source.onActive = false;
                    source.requestID = repData[header][0].requestID;
                }
                break;
            case 'spVerificationRequest':
                state.router.push('/userverificationforapproval');
                break;
            case 'spGetApprovalRequest':
            // console.log(repData[header][0]);
                source.itemList = response.data[response.data.Header];
                break;
            case 'annexBCG':
                    // Caller : SelectCategory, api/index/executePost
                    // console.log('api/annexBCG/source - accoun title',
                    // state.current.code + '.-.' +  state.current.accId );
                    store.commit('annexBCG', response.data[response.data.Header]);
                    if (state.bcg.accntActivated) {
                        state.current.codeMthnode = state.current.code + '-' +  state.current.accId;
                        store.state.api.executeGet(store,
                            'procedurename=spGetAccountTitles&motherNode=' + state.current.codeMthnode  +
                            '&brgyID=' + state.current.brgyID +
                            '&yr=' + state.current.budgetYear +
                            '&fppid=' + state.current.fppid);
                    }

                    break;
            case 'spGetAccountTitles':
                    // console.log('spGetAccountTitles', response.data[response.data.Header]);
                    store.commit('spGetAccountTitles', response.data[response.data.Header]);
                    break;
            case 'Get_AnnexAReport':
                    // console.log('Get_AnnexAReport', response.data[response.data.Header]);
                    store.commit('Get_AnnexAReport', response.data[response.data.Header]);
                    break;
            case 'spGet_AnnexDReport':

                    store.commit('Get_AnnexDReport', response.data[response.data.Header]);
                    break;
            case 'spGet_AnnexEReport':

                    // console.log('Get_AnnexAReport', response.data[response.data.Header]);
                    store.commit('Get_AnnexEReport', response.data[response.data.Header]);
                    store.state.api.executeGet(store, 'procedurename=titleposition');
                    break;
            case 'spGet_AnnexFReport':
                // console.log('Get_AnnexAReport', response.data[response.data.Header]);
                store.commit('Get_AnnexFReport', response.data[response.data.Header]);
                break;
            case 'spAnnexAUpdate':
                // console.log('post Get_AnnexAReport', response.data[response.data.Header]);
                store.commit('Get_AnnexAReport', response.data[response.data.Header]);
                break;
            case 'titleposition':
                // console.log('titleposition', response.data[response.data.Header]);
                store.commit('titleposition', response.data[response.data.Header]);
                const field = state.headerAnnexE.filter((a: any) => a.value === 'position')[0];
                field.items = state.titleposition.map((a: any) => a.position);

                store.state.api.executeGet(store, 'procedurename=gradeStep');

                break;
            case 'gradeStep':

                store.commit('gradeStep', response.data[response.data.Header]);
                const gradestep = state.headerAnnexE.filter((a: any) => a.value === 'gradestep')[0];
                const gradestepBudgetyear = state.headerAnnexE.filter((a: any) =>
                                    a.value === 'gradestep_budgetyear')[0];
                gradestepBudgetyear.items = gradestep.items = state.gradeStep.map((a: any) => a.gradestep);

                break;
            case 'spGetProjects':

                store.commit('spGetProjects', response.data[response.data.Header]);
                // console.log('spGetProjectss', state.spGetProjects);
                break;


            case 'spGetBarangayStatus':
                state.loading = false;
                // console.log('api/spGetBarangayStatus', response.data[response.data.Header]);
                store.commit('spGetBarangayStatus', response.data[response.data.Header]);
                // console.log('api/sources', source);
                if (this.hasKey(source, 'callBack') === true) {
                    source.callBack();
                }
                // source = state.spGetBarangayStatus;
                // console.log('api/spGetBarangayStatus', source);
                break;

            default:

        }
    }
    public async executePost(store: any, params: any, source: any) {
        const state = store.state;
        const baseUrl = store.state.baseUrl;
        const response = await state.axios({
                            method: 'POST',
                            url: baseUrl,
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                              },
                            data: params,

                        });
        // console.log('api Post', response);

        const header = response.data.Header;
        switch (header) {
            case 'spSetApprovalRequest':
                source.itemList = response.data[response.data.Header];
                break;
            case 'spSetRejectedRequest':
                source.itemList = response.data[response.data.Header];
                break;
            case 'spAnnexAUpdate':
                store.commit('Get_AnnexAReport', response.data[response.data.Header]);
                source.clear();
                break;
            case 'spAnnexDUpdate':
                store.commit('Get_AnnexDReport', response.data[response.data.Header]);
                source.clear();
                break;
            case 'spAnnexEUpdate':
                store.commit('Get_AnnexEReport', response.data[response.data.Header]);
                source.clear();
                break;
            case 'spAnnexFUpdate':
                store.commit('Get_AnnexFReport', response.data[response.data.Header]);
                source.clear();
                break;
            case 'spAnnexBCGUpdate':
                // console.log('api/spAnnexBCGUpdate/state.current', state.current);
                // console.log('spAnnexBCGUpdate', header);
                this.forceLoad(state, 'annexBCG');
                state.dialog.save = false;
                store.state.api.executeGet(store,
                    'procedurename=annexBCG&brgyID=' + state.current.brgyID +
                    '&Budget_year=' +  state.current.budgetYear +
                    '&code=' +  state.current.code,
                    state.current, true);

                break;
            case 'spdeleteAnnexBCG':
                this.forceLoad(state, 'annexBCG');
                state.dialog.delete = false;
                store.state.api.executeGet(store,
                    'procedurename=annexBCG&brgyID=' + state.current.brgyID +
                    '&Budget_year=' +  state.current.budgetYear +
                    '&code=' +  state.current.code,
                    state.current, true);
                break;

            case 'spSetAccountTitles':
                // console.log('api', response.data[response.data.Header][0]);

                source.notification = response.data[response.data.Header][0].notification;
                source.onSuccess = (response.data[response.data.Header][0].success === 'False') ? false : true;
                (source.reff.form1 as Vue & { validate: () => boolean }).validate();
                source.loading = false;
                if (source.onSuccess ) {
                    source.title = '';
                    source.code = '';
                    source.atAdd = false;

                    this.GetAccountTitles(store);

                }


                break;

            case 'spSetAccountTitles_Sub':
                // console.log('api', response.data[response.data.Header][0]);
                source.notification = response.data[response.data.Header][0].notification;
                source.onSuccess = (response.data[response.data.Header][0].success === 'False') ? false : true;
                (source.reff.form2 as Vue & { validate: () => boolean }).validate();
                source.loading = false;
                if (source.onSuccess ) {
                    source.title = '';
                    source.code = '';
                    source.atAddSub = false;

                    this.GetAccountTitles(store);

                }


                break;
            case 'spGetAccountTitles_Edit':
                // console.log('api', response.data[response.data.Header][0]);
                source.notification = response.data[response.data.Header][0].notification;
                source.onSuccess = (response.data[response.data.Header][0].success === 'False') ? false : true;
                (source.reff.form3 as Vue & { validate: () => boolean }).validate();
                source.loading = false;
                if (source.onSuccess ) {
                    source.title = '';
                    source.code = '';
                    source.atEdit = false;

                    this.GetAccountTitles(store);

                }


                break;
            case 'spGetAccountTitles_Delete':
                // console.log('api', response.data[response.data.Header][0]);
                // console.log('hit delete');
                source.notification = response.data[response.data.Header][0].notification;
                source.onSuccess = (response.data[response.data.Header][0].success === 'False') ? false : true;

                source.loading = false;
                if (source.onSuccess ) {
                    source.atDelete = false;
                    // console.log('calling GetAccountTitles');
                    this.GetAccountTitles(store);
                }

                break;
            case 'spSetBarangayProject':
                // console.log('api', response.data[response.data.Header][0]);
                source.notification = response.data[response.data.Header][0].notification;
                source.onSuccess = (response.data[response.data.Header][0].success === 'False') ? false : true;
                (source.reff.form1 as Vue & { validate: () => boolean }).validate();
                source.loading = false;
                if (source.onSuccess ) {
                    source.atAdd = false;
                    this.GetProjects(store);

                }
                break;
            case 'spSetBarangayProject_Edit':
                // console.log('api', response.data[response.data.Header][0]);
                source.notification = response.data[response.data.Header][0].notification;
                source.onSuccess = (response.data[response.data.Header][0].success === 'False') ? false : true;
                (source.reff.form2 as Vue & { validate: () => boolean }).validate();
                source.loading = false;
                if (source.onSuccess ) {
                    source.atEdit = false;
                    state.current.projSelected = source.title;
                    state.annexCategory.alterHeader = source.title;
                    this.GetProjects(store);

                }
                break;
            case 'spSetBarangayProject_Delete':

                // console.log('api', response.data[response.data.Header][0]);
                source.notification = response.data[response.data.Header][0].notification;
                source.onSuccess = (response.data[response.data.Header][0].success === 'False') ? false : true;
                // (source.reff.form2 as Vue & { validate: () => boolean }).validate();
                source.loading = false;
                if (source.onSuccess ) {
                    source.atDelete = false;

                    this.GetProjects(store);

                }
                break;
            case 'spdeleteAnnexAdetails':
                state.dialog.delete = false;
                store.state.api.executeGet(store,
                    'procedurename=Get_AnnexAReport&brgy_id=' + state.current.brgyID +
                    '&year=' +  state.current.budgetYear, true);
                break;
            case 'spdeleteAnnexDdetails':
                state.dialog.delete = false;
                store.state.api.executeGet(store,
                    'procedurename=spGet_AnnexDReport&brgy_id=' + state.current.brgyID +
                    '&year=' +  state.current.budgetYear, true);
                break;
            case 'spdeleteAnnexEdetails':
                state.dialog.delete = false;
                store.state.api.executeGet(store,
                    'procedurename=spGet_AnnexEReport&brgy_id=' + state.current.brgyID +
                    '&year=' +  state.current.budgetYear, true);
                break;
            case 'spdeleteAnnexFdetails':
                state.dialog.delete = false;
                store.state.api.executeGet(store,
                    'procedurename=spGet_AnnexFReport&brgy_id=' + state.current.brgyID +
                    '&year=' +  state.current.budgetYear, true);
                break;
            case 'sp_Upload_IncomeStatement':
                store.commit('loading', false );
                store.commit('annexBCG', response.data[response.data.Header], true);
                if (state.bcg.accntActivated) {
                    source.codeMthnode = source.code + '-' +  source.accId;
                    store.state.api.executeGet(store,
                        'procedurename=spGetAccountTitles&motherNode=' + source.codeMthnode  +
                        '&brgyID=' + source.brgyID +
                        '&yr=' + source.budgetYear +
                        '&fppid=' + source.fppid, true);
                }
                break;

        }
    }

    private forceLoad(state: any, header: string) {
        // console.log('forceLoad header', header);
        // console.log('forceLoad state.current', state.current);
        // console.log('forceLoad state.current.brgyID', state.current.brgyID);
        // console.log('forceLoad state.current.budgetYear', state.current.budgetYear);
        // const f = state.apiCalls.filter((o: any) => (o.header === header) &&
        //                                             (o.brgyID === state.current.brgyID) &&
        //                                             (o.budgetYear === state.current.budgetYear)) ;
        let f = state.apiCalls.filter((o: any) => (o.header === header));
        // console.log('f', f);
        f = f.filter((o: any) => (o.brgyID === state.current.brgyID.toString()));
        // console.log('f', f);
        f = f.filter((o: any) => (o.budgetYear === state.current.budgetYear.toString()));
        // console.log('f', f);
        f.map((m: any) => {
            m.forceLoading = true;
        });
        // console.log('f final', f);
        // console.log('state.apiCalls', state.apiCalls);
    }
    private async GetAccountTitles(store: any) {
        const state = store.state;
        if (state.bcg.accntActivated) {
            // console.log('calling GetAccountTitles executeGet');
            store.state.api.executeGet(store,
                'procedurename=spGetAccountTitles&motherNode=' + state.current.codeMthnode  +
                '&brgyID=' + state.current.brgyID +
                '&yr=' + state.current.budgetYear +
                '&fppid=' + state.current.fppid,
                state.current,
                true);
        }
    }
    private async GetProjects(store: any) {
        const state = store.state;
        state.api.executeGet(store,
            'procedurename=spGetProjects&brgyID=' + state.current.brgyID);

    }
    private  hasKey(obj: any, name: string) {
        for (const key in obj) {
            if (name === key) {
                return true;
            }
        }

        return false;
    }

}


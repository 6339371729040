import { Vue, Component } from 'vue-property-decorator';
import Header from './components/header/Header.vue';
import Login from './components/login/Login.vue';
import Footer from './components/footer/Footer.vue';
import {Api} from './api';
import {Drawing} from './api/drawing';
import axios from 'axios';
import config from './config.json';
import LeftNav from './components/left-nav/LeftNav.vue';

@Component({
    components: {
      Header,
      LeftNav,
      Login,
      Footer,
    },
  })
export default class App extends Vue {
  public state = this.$store.state;
  public item =  1;
  public items =  [
    { text: 'Real-Time', icon: 'mdi-clock' },
    { text: 'Audience', icon: 'mdi-account' },
    { text: 'Conversions', icon: 'mdi-flag' },
  ];

  public objHandler = {
    router: {},
    api: {},
    baseUrl: '',
    apiKey: '',
    axios: {},
    Draw: {},
    navMenuLeft: {},
    navFormItems: {},
    navReportItems: {},
    navBarangayItems: {},
    headerAnnexA: {},
    defaultAnnexA: {},
    headerAnnexD: {},
    defaultAnnexD: {},
    headerAnnexE: {},
    defaultAnnexE: {},
    headerAnnexF: {},
    defaultAnnexF: {},
  };
  public navFormItems =
  [
    { text: 'Annex A', icon: 'mdi-alpha-a-circle-outline', subIcon: '', id: 'AnnexA',
    path: 'annexa', header: 'View Annex A', title: '', subView: '', code: '' , group: 'forms'},
    { text: 'Annex B, C, G', icon: 'mdi-alpha-b-circle-outline', subIcon: '', id: 'AnnexBCG' ,
    path: 'annexbcg', header: 'View Annex D', title: '', subView: '', code: '', group: 'forms'},
    { text: 'Annex D', icon: 'mdi-alpha-d-circle-outline' , subIcon: '', id: 'AnnexD' ,
    path: 'annexd', group: 'forms'},
    { text: 'Annex E', icon: 'mdi-alpha-e-circle-outline' , subIcon: '', id: 'AnnexE',
    path: 'annexe', group: 'forms'},
    { text: 'Annex F', icon: 'mdi-alpha-f-circle-outline' , subIcon: '', id: 'AnnexF',
    path: 'annexf' , group: 'forms'},
  ];
  public navReportItems =
  [
    { text: 'Annex A', icon: 'mdi-alpha-a-circle-outline', id: 'annexA',
    path: '/rptannexa' , group: 'report'},
    { text: 'Annex B', icon: 'mdi-alpha-b-circle-outline', id: 'annexB',
    path: '/rptannexb' , group: 'report'},
    { text: 'Annex C', icon: 'mdi-alpha-c-circle-outline', id: 'annexC',
    path: '/rptannexc' , group: 'report'},
    { text: 'Annex D', icon: 'mdi-alpha-d-circle-outline' , id: 'annexD',
    path: '/rptannexd', group: 'report'},
    { text: 'Annex E', icon: 'mdi-alpha-e-circle-outline' , id: 'annexE',
    path: '/rptannexe', group: 'report'},
    { text: 'Annex F', icon: 'mdi-alpha-e-circle-outline' , id: 'annexF',
    path: '/rptannexf', group: 'report'},
    { text: 'Annex G', icon: 'mdi-alpha-g-circle-outline' , id: 'annexG',
    path: '/rptannexg', group: 'report'},
  ];
  public navBarangayItems =
  [
    { text: 'Year 2021', icon: '', id: '2021',
    path: '/barangayList/2021', group: 'year'},
    { text: 'Year 2020', icon: '', id: '2020',
    path: '/barangayList/2020', group: 'year'},
    { text: 'Year 2019', icon: '', id: '2019',
    path: '/barangayList/2019', group: 'year'},
    { text: 'Year 2018', icon: '' , id: '2018',
    path: '/barangayList/2018', group: 'year'},
    { text: 'Year 2017', icon: '' , id: '2017',
    path: '/barangayList/2017', group: 'year'},
    { text: 'Year 2016', icon: '' , id: '2016',
    path: '/barangayList/2016', group: 'year'},
  ];
  public mounted() {
    // console.log('app.ts')
    this.objHandler.navMenuLeft = this.navFormItems;
    this.objHandler.navReportItems = this.navReportItems;
    this.objHandler.navFormItems = this.navFormItems;
    this.objHandler.navBarangayItems = this.navBarangayItems;

    this.objHandler.router = this.$router;
    this.objHandler.axios = axios;
    this.objHandler.baseUrl = config.api.baseUrl;
    this.objHandler.apiKey = config.api.masterKey;
    this.objHandler.api = new Api();
    this.objHandler.Draw = new Drawing();
    this.objHandler.headerAnnexA = config.headerAnnexA;
    this.objHandler.defaultAnnexA = config.defaultAnnexA;
    this.objHandler.headerAnnexD = config.headerAnnexD;
    this.objHandler.defaultAnnexD = config.defaultAnnexD;
    this.objHandler.headerAnnexE = config.headerAnnexE;
    this.objHandler.defaultAnnexE = config.defaultAnnexE;
    this.objHandler.headerAnnexF = config.headerAnnexF;
    this.objHandler.defaultAnnexF = config.defaultAnnexF;
    this.$store.commit('objHandler', this.objHandler);
  }
  public onResize() {

    if (window.innerWidth >= 1440) {
      this.$store.commit('showdrawer');
    }
}
}

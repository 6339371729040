import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import CrudBalance from '../CrudBalance.vue';
import CrudAT from '../CrudAT.vue';
import ProjectMenu from '../ProjectMenu.vue';
import IbcgSelectors from '../IbcgSelectors.vue';
import XLSX from 'xlsx';

// import VueHtml2Canvas from 'vue-html2canvas';

@Component({
  components: {
    ProjectMenu,
    CrudBalance,
    CrudAT,
    IbcgSelectors,
  },
})
export default class Crud extends Vue {
    // https://codepen.io/Event_Horizon/pen/WodMjp
    public spUserVerification = this.$store.state.spUserVerification;
    public state = this.$store.state;
    public getters = this.$store.getters;
    public mEditMode = false ;

    @Prop(Boolean) public editModeCrud: boolean | undefined;

    public downloadpdf() {
      const header = this.getters.getHeadersAnnexBCG;
      const details = this.state.reportDetails;
      this.state.Draw.ReportPdf(
        header,
        this.state.annexBCG,
        details);

    }

    public preview() {
      if (this.state.current.annexBCG.printPreview) {
        if (this.state.Draw.myCanvas !== null) {
          // this.state.Draw.ReportCanvas([], 'AnnexB');
        }
      }

    }
    public upload(file: any) {
      // console.log('hit1');
      const input = this.$refs.fileinput;

      const reader = new FileReader();
      const files = (this.$refs.fileinput as any).files;
      const store =  this.$store;
      // console.log('hit2');
      files.forEach((o: any) => {

        reader.onload = (e: any) => {
          const data = (e.target as any).result;
          const bytes = new Uint8Array(data);
          const length = bytes.byteLength;
          let binary = '';

          for (let i = 0; i < length; i++) {
              binary += String.fromCharCode(bytes[i]);
          }

          // var o = "", l = 0, w = 10240;
          // for(; l < data.byteLength / w; ++l)
          // o+=String.fromCharCode.apply(null,new Uint8Array(data.slice(l*w,l*w+w)));
          // o+=String.fromCharCode.apply(null, new Uint8Array(data.slice(l*w)));



          // console.log('crud/o',o);
          // console.log('crud/binary',binary);
          const fixedData = binary;
          const workbook = XLSX.read(btoa(fixedData), {type: 'base64'});
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
          const results = XLSX.utils.sheet_to_json(worksheet);

          // console.log('crud/results b',results);
          // let d = [{values:0}];
          // d =  results.filter((o: any) => o.values !== 'n/a');
          // for(let i = 0 ; i > d.length; i++) {

          // }

          const d =  results.filter((x: any) => x.value !== '-');
          // console.log('crud/values', d);
          const str = JSON.stringify(results) ;
          // console.log((d[0] as any).value);
          // console.log((d[1] as any).value);
          // console.log(this.state.spGetBarangayList);
          const brgy = this.state.spGetBarangayList.filter((b: any) => b.brgy_name === (d[1] as any).value);
          const isnumber = Number((d[0] as any).value);
          // console.log(isnumber);
          if (brgy.length === 0 ) { alert('Barangay name not found'); }
          if (isnumber === 0 ) { alert('Invalid Year'); }

          if ((isnumber > 0) && (brgy.length > 0 )) {

            const item = brgy[0];
            // console.log('brgy_name', item.brgy_name);
            this.state.current.brgyID = item.brgy_id;
            this.state.current.brgy_name = item.brgy_name;
            this.state.current.brgy_district = item.brgy_district;
            this.state.current.captain = item.captain;
            this.state.current.secretary = item.secretary;
            this.state.current.treasurer = item.treasurer;

            const param = {
              parameters:
              {
                  key: store.state.apiKey,
                  procedurename: 'sp_Upload_IncomeStatement',
                  filename: (d[1] as any).value,
                  year: (d[0] as any).value,
                  brgyName: (d[1] as any).value,
                  data : str,
              },
            };
            // console.log('crud/values', param);
            store.commit('loading', true );
            store.state.api.executePost(store, param, store.state.current);


          }


        };

        reader.readAsArrayBuffer(o);

      });

      (this.$refs.fileinput as any).value = null;
    }



}


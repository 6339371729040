import { render, staticRenderFns } from "./UserVerification.vue?vue&type=template&id=187c6d5d&scoped=true&"
import script from "./user-verification.ts?vue&type=script&lang=ts&"
export * from "./user-verification.ts?vue&type=script&lang=ts&"
import style0 from "./user-verification.scss?vue&type=style&index=0&id=187c6d5d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "187c6d5d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContent } from 'vuetify/lib/components/VContent';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAutocomplete,VBtn,VCard,VContent,VFileInput,VFlex,VForm,VLayout,VProgressLinear,VTextField})

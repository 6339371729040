import { Vue, Component, Watch } from 'vue-property-decorator';
import CrudBalance from '../CrudBalance.vue';
import Crud from '../Crud.vue';
import store from '@/store';
@Component({
    components: {
      CrudBalance,
      Crud,
    },
  })
export default class Verification extends Vue {
    public loading = true;
    public state = this.$store.state;
    public getters = this.$store.getters;
    public editModeReport = false;
    public pagination = {
        rowsPerPage : 100,
    };

    public mounted() {
        this.editModeReport = true;
        this.$store.commit('loading', true );
        this.$store.state.current.status = this.$store.state.status.annexBCG;
        this.$store.state.api.executeGet(this.$store,
            'procedurename=annexBCG&brgyID=' + this.state.current.brgyID +
            '&Budget_year=' +  this.state.current.budgetYear +
            '&code=' +  this.state.current.code );
    }
    public indent(lvl: any) {
        return '_'.repeat(lvl);
    }
    public editItem(item: any) {
        this.assignValues(item);
        this.state.dialog.save = true;

        return '';
    }
    public deleteItem(item: any) {

        this.assignValues(item);
        this.state.dialog.delete = true;
        return '';
    }

    public deleteExecute() {
        const param = {
            parameters:
            {
                key: this.$store.state.apiKey,
                procedurename: 'spdeleteAnnexBCG',
                brgy_id: this.state.current.brgyID,
                budget_year: this.state.current.budgetYear,
                FPP_id : this.state.current.fppid,
                account_id : this.state.crudAT.account_id ,
                budgetTypeID : this.state.current.budgetTypeID,
            },
        };
        this.$store.commit('loading', true );
        this.$store.state.api.executePost(this.$store, param, this.state.current);
        this.state.dialog.delete = false;

    }

    private assignValues(item: any) {
        const res = item.Code.split('-ACC-');
        const fppcode = res[0];
        const accID = 'ACC-' + res[1].split('-')[0];
        const accode = fppcode + '-ACC-' + res[1].split('-')[0];
        const fppnode = this.state.annexBCG.filter((a: any) => a.Code === fppcode)[0] ;
        const majorAccnt = this.state.annexBCG.filter((a: any) => a.Code === accode )[0] ;
        // console.log('reports',accode);
        // console.log('reports',item);
        // console.log('reports.ts/res', res);
        // console.log('reports.ts/accode', accode);
        // console.log('reports.ts/mthnode', fppnode);
        // console.log('reports.ts/majorAccnt', majorAccnt);
        // console.log('reports.ts/state.annexBCG',this.state.annexBCG);
        // console.log('reports.ts/item', item);
        // console.log('reports.ts/annexCategory', this.state.annexCategory);

        // console.log('reports.ts/indexOf',fppcode.indexOf('FPP-22-29'));


        const category = [
            {header: 'View Annex B,C, and G', headerTitle: 'View Annex B,C, and G',
                title: '', subView: '', code: '', fppid: '', isValue: false,
                icon: 'mdi-file-eye'},
            {header: 'Beginning Balance', headerTitle: 'Beginning Balance',
                title: '', subView: '', code: 'FPP-19', fppid: '19' , isValue: true,
                icon: 'mdi-wallet'},
            {header: 'Income', headerTitle: 'Income', title: 'Income', subView: '',
                code: 'FPP-20', fppid: '20', isValue: true, icon: 'mdi-bank'},
            {header: 'Expenditure', headerTitle: 'Expenditure', title: 'Expenditure',
                subView: '', code: 'FPP-22', fppid: '22' , isValue: false,
                icon: 'mdi-gold'},
            {header: 'Special Purpose Appropriation',
                headerTitle: 'Special Purpose Appropriation', title: 'Special Purpose Appropriation',
                subView: 'Expenditure', code: 'FPP-22-23', fppid: '23',
                isValue: true, icon: 'mdi-subdirectory-arrow-right'},
            {header: 'General Administration', headerTitle: 'General Administration',
                title: 'General Administration',
                subView: 'Expenditure', code: 'FPP-22-27', fppid: '27',
                isValue: false, icon: 'mdi-subdirectory-arrow-right'},
            {header: 'Current Operating Expenditure',
                headerTitle: 'Current Operating Expenditure',
                title: 'Current Operating Expenditure',
                subView: 'Expenditure/ General Administration', code: 'FPP-22-27-28', fppid: '28',
                isValue: false, icon: 'mdi-subdirectory-arrow-right'},
            {header: 'Personal Services', headerTitle: 'Personal Services',
                title: 'Personal Services',
                subView: 'Expenditure/ General Administration/ Current Operating Expenditure',
                code: 'FPP-22-27-28-ACC-00407',
                fppid: '28', isValue: true, icon: 'mdi-subdirectory-arrow-right'},
            {header: 'Maintenance and Other Operating Expenses',
                headerTitle: 'Maintenance and Other Operating Expenses',
                title: 'Maintenance and Other Operating Expenses',
                subView: 'Expenditure/ General Administration/ Current Operating Expenditure',
                code: 'FPP-22-27-28-ACC-0435', fppid: '28',
                isValue: true, icon: 'mdi-subdirectory-arrow-right'},
            {header: 'Infrastruture Project', headerTitle: 'Infrastruture Project',
                title: 'Infrastruture Project',
                subView: 'Expenditure/ General Administration', code: 'FPP-22-27-36', fppid: '36',
                isValue: true, icon: 'mdi-subdirectory-arrow-right'},
            {header: 'Capital Outlay', headerTitle: 'Capital Outlay',
                title: 'Capital Outlay',
                subView: 'Expenditure/ General Administration', code: 'FPP-22-27-84', fppid: '84',
                isValue: true, icon: 'mdi-subdirectory-arrow-right'},
            {header: 'Projects', headerTitle: 'Projects', title: 'Expenditure',
                subView: 'projects', code: 'FPP-22-29', fppid: '29',
                isValue: true, icon: 'mdi-subdirectory-arrow-right'},
        ];
        const header = category.filter((a: any) => (item.Code.indexOf(a.code) > -1 ) && (a.isValue === true)) [0];
        // console.log('reports/header', header);
        // console.log('reports.ts/this.state.bcg',this.state.bcg);

        if (fppcode.indexOf('FPP-22-29') === 0) {
            if (this.state.bcg.projectActive) {
                const hdr = {
                    header: 'ProjectActive',
                    headerTitle: 'Projects',
                    title: majorAccnt.Title.split('. ')[1],
                    alterHeader: store.state.annexCategory.alterHeader,
                    subView: 'projects',
                    code: store.state.current.code,
                    accId: accID,
                    fppid: store.state.current.fppid};

                    // console.log('reports.ts/hdr true',hdr);
                this.$store.commit('annexCategory', hdr);
            } else {
                const hdr = {
                    header: 'ProjectActive',
                    headerTitle: 'Projects',
                    title: majorAccnt.Title.split('. ')[1],
                    alterHeader: fppnode.Title.split('. ')[1],
                    subView: 'projects',
                    code: fppcode,
                    accId: accID,
                    fppid: fppnode.ID};


                    // console.log('reports.ts/hdr false',hdr);
                this.$store.commit('annexCategory', hdr);
            }

        } else {
            this.$store.commit('annexCategory', header);
        }

        // console.log('reports.ts/this.state.current', this.state.current);

        // console.log('reports.ts/state.bcg',this.state.bcg);
        // console.log('reports.ts/item.Code',item.Code);
        // console.log('reports.ts/header', header);
        // console.log('reports.ts/this.state.spGetAccountTitles', this.state.spGetAccountTitles);
        // const data = this.state.spGetAccountTitles.filter((a: any) => a.account_id === item.ID);
        // console.log('reports.ts/data', data);
        // this.state.AccountTitles = data;
        // console.log('reports/item', item);
        const data = this.state.spGetAccountTitles.filter((a: any) =>
            a.account_id === item.ID);
        this.state.AccountTitles = data;
        // console.log('reports/AccountTitles', data);
        //
        this.state.crudAT.search =   item.accountTitle;
        // console.log('reports/data[0]',data[0]);
        this.state.crudAT.accountTitle = item.accountTitle;

        this.state.crudAT.accountCode = item.t2;
        this.state.crudAT.account_id = item.ID;

        this.state.crudAT.pastyearActual = item.Amount_past;
        this.state.crudAT.firstSemActual = item.Amount_first;
        this.state.crudAT.secondSemEsimate = item.Amount_second;
        this.state.crudAT.BudgetyearProposed = item.Amount_proposed;
        this.state.crudAT.Curring = item.t9;
        this.state.crudAT.expected_results = item.expected_results;
        this.state.crudAT.performance_indicator = item.Performance_Indicator;
        // console.log('reports/this.state.crudAT', this.state.crudAT);
    }

}


import { Vue, Component } from 'vue-property-decorator';
import AT from '../component/AT.vue';
@Component({
    components: {
        AT,
    },
})
export default class Lab1 extends Vue {
    public spUserVerification = this.$store.state.spUserVerification;
    public state = this.$store.state;

}


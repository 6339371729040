import { Vue, Component } from 'vue-property-decorator';
import SelectCategory from '../SelectCategory.vue';
import Project from '../Project.vue';

@Component({
    components: {
      SelectCategory,
      Project,
    },
  })
export default class Toolbar extends Vue {
    public spUserVerification = this.$store.state.spUserVerification;
    public state = this.$store.state;


    public items = [
        {icon: 'mdi-asessment', title: 'Gen-Add Personal Services'},
        {icon: 'mdi-assignment', title: 'Gen-Add MOOE'},
        {icon: 'mdi-card_travel', title: 'Gen-Add Infrastructure Projects'},
        {icon: 'mdi-account_balance_wallet', title: 'Gen-Add Capital Outlay'},
        {icon: 'mdi-build', title: 'Projects'},
    ];

    public get projects() {
      // console.log ('spGetProjects', this.state.spGetProjects.map((a:any) => a.FPP_name) )
      // alert('hit plutomusang');
      return this.state.spGetProjects === {} ? [] : this.state.spGetProjects.map((a: any) => a.FPP_name);
      // return this.state.spGetProjects.map((a:any) => a.FPP_name);
    }

    public changeProject() {
        // console.log('this.state.spGetProjects', this.state.spGetProjects);

        const fpp =   this.state.spGetProjects.filter((a: any) => a.FPP_name === this.state.current.projSelected)[0];
        // console.log(fpp);
        const data = {
          header: 'ProjectOpen',
          headerTitle: 'Projects',
          title: 'Select Account Category',
          alterHeader: this.state.current.projSelected,
          code: 'FPP-22-29-' + fpp.FPP_id,
          fppid: fpp.FPP_id};

        this.$store.commit('annexCategory', data);
        this.$store.commit('loading', true );
        this.$store.state.api.executeGet(this.$store,
              'procedurename=annexBCG&brgyID=' + this.state.current.brgyID +
              '&Budget_year=' +  this.state.current.budgetYear +
              '&code=' +  this.state.current.code,
              this.state.current);
        // console.log('annexBCG','procedurename=annexBCG&brgyID=' + this.state.current.brgyID +
        //   '&Budget_year=' +  this.state.current.budgetYear +
        //   '&code=' +  this.state.current.code);
        // console.log('fpp', fpp);
    }

    public mounted() {

      this.$store.state.api.executeGet(this.$store,
          'procedurename=spGetProjects&brgyID=' + this.state.current.brgyID);
    }


    public statusUpdated(value: any) {
      this.state.status.annexBCG = value;

      const param = {
        parameters:
        {
            key: this.$store.state.apiKey,
            procedurename: 'spSet_AnnexStatus',
            statusID: this.state.status.annexBCG,
            header: this.state.current.header,
            brgy_id:  this.state.current.brgyID,
            budgetYear:  this.state.current.budgetYear,
            budgetTypeId: this.state.current.budgetTypeID,
        },
    };

      this.$store.state.api.executePost(this.$store, param, this.state.current);

    }

}


import { Vue, Component } from 'vue-property-decorator';
import { Auth } from 'aws-amplify';

@Component
export default class Verification extends Vue {
    public objHandler = {email: '', password: '', repeatPassword: '', code: '',  error: '', success: '', router: {}};
    public valid = false;

    public mounted() {
        this.objHandler.router = this.$router;
    }

    public submit = async ( obj: any) => {
        try {
            alert('submit');
            await Auth.forgotPassword(obj.email);
            obj.success = 'Message sent, please check your email.';
            obj.error = '';
        } catch (err) {
            obj.error = err.message;
            obj.sucess = '';
        }
    }
}


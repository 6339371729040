import { render, staticRenderFns } from "./Nemtreetest.vue?vue&type=template&id=cd33bebc&scoped=true&"
import script from "./nemtreetest.ts?vue&type=script&lang=ts&"
export * from "./nemtreetest.ts?vue&type=script&lang=ts&"
import style0 from "./nemtreetest.scss?vue&type=style&index=0&id=cd33bebc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cd33bebc",
  null
  
)

export default component.exports
<template>
    <v-menu offset-y>
        <template v-slot:activator="{on}">
            <v-btn class="caption"  v-on="on">
                {{label}}
                <v-icon small>
                    mdi-menu-down
                </v-icon>
            </v-btn>
        </template>
        <v-card>
            <v-list dense class="caption">
                <v-list-item-group color="primary">
                    <v-list-item @click="clicked('Ongoing')">
                        <v-list-item-icon>
                            <v-icon>mdi-run-fast</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                Ongoing
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="clicked('Completed')">
                        <v-list-item-icon>
                            <v-icon>mdi-eye</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                Completed
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>       
                    <v-list-item @click="clicked('Approved')">
                        <v-list-item-icon>
                            <v-icon>mdi-thumb-up</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title >
                                Approved
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>                                                                                                                   
                </v-list-item-group>
            </v-list>
        </v-card>
    </v-menu>
</template>

<script>
export default {
    props: ['label'],
    methods: {
        clicked(msg) {
            this.$emit('statusChanged', msg);
        },
    },
};
</script>
<template>
    <span class ="inputs">
        
        <v-text-field 
            v-model="Item.model"
            :label=Item.text
            v-on:keypress="keymonitor"
            outlined
            dense
            v-if="Item.inputType==='v-text-field'"
        >
        </v-text-field>

        <v-textarea
            v-model="Item.model"
            :label=Item.text
            v-on:keypress="keymonitor"
            outlined
            dense
            v-if="Item.inputType==='v-textarea'"
        >
        </v-textarea>         


        <v-autocomplete
            v-model="Item.model"
            :label=Item.text
            :items="Item.items"
            v-on:keypress="keymonitor"
            outlined
            dense
            v-if="Item.inputType==='v-autocomplete'"
        >
        </v-autocomplete>

        <v-menu
            ref="menu1"
            v-model="Item.menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            full-width
            max-width="290px"
            min-width="290px"
            v-if="Item.inputType==='v-date-picker'"
        >
            <template v-slot:activator="{ on }">
            <v-text-field
                v-model="Item.model"
                :label=Item.text
                hint="YYYY/MM/DD format"
                dense
                persistent-hint
                outlined
                prepend-icon="mdi-event"
                v-on="on"
            ></v-text-field>
            </template>
            <v-date-picker v-model="Item.model" no-title @input="Item.menu = false"></v-date-picker>
        </v-menu>
    </span>
</template>

<script>
export default {
    props: ['Item'],
    methods: {
        keymonitor(evt) {
            // console.log('inputs/Item', this.Item);
            if (this.Item.dataType === 'date') {
                evt = (evt) ? evt : window.event;
                const charCode = (evt.which) ? evt.which : evt.keyCode;
                if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 47) {
                    evt.preventDefault();
                } else {
                    return true;
                }
            }

            if (this.Item.dataType === 'int') {
                evt = (evt) ? evt : window.event;
                const charCode = (evt.which) ? evt.which : evt.keyCode;
                if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                    evt.preventDefault();
                } else {
                    return true;
                }
            }
        },
        numberWithCommas(x) {
            x = x.replace(/[^\d.-]/g, '');
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },
    },
    watch: {
        Item: {
            handler(newValue) {

                if (newValue.dataType === 'int') {
                    // console.log('Inputs',newValue.model.replace(/\D/g,''));
                    // const x = newValue.model.replace(/[^\d.-]/g,'');
                    // console.log('Inputs', x);
                    // // console.log('hit');
                    // this.Item.model = x;
                    // console.log('Inputs/model', newValue.model);
                    newValue.model = this.numberWithCommas(newValue.model);
                }
            },
            deep: true,
        },
    },
};
</script>
import { Vue, Component } from 'vue-property-decorator';
import TreeCell from './TreeCell/TreeCell.vue';
import NemTree from './NemTree/NemTree';
import TreeNode from './NemTree/TreeNode';
import treeData from './sampleData/treeData.json';

@Component({
    components: {
        TreeCell,
        },
})
export default class Nemtreetest extends Vue {
    public sourceTree: NemTree = new NemTree();
    public Show: boolean = true;
    public mounted() {

        // console.log("data", treeData);
        treeData.forEach((data) => {
            // console.log('data',data.Code);
            this.populateNemTree(this.sourceTree, data);
        });
        // console.log("sourceTree", this.sourceTree );

      }
    private populateNemTree(sourceTree: NemTree, data: any ) {
        const node: TreeNode = new TreeNode(sourceTree);
        node.code = data.Code;

        node.addField('Value', data.Value);
        node.addField('Minutes', data.Minutes);
        node.addField('StrtDate', data.StrtDate);
        node.addField('Status', data.Status);

        sourceTree.nodes.push(node);

    }
}

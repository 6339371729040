import { Vue, Component, Watch } from 'vue-property-decorator';
import Report from '../Report.vue';
// import AT from '../../../../_labratory/component/AT.vue';
import AT from '../AT.vue';
export type VForm = Vue & { resetValidation: () => boolean };

@Component({
    components: {
      Report,
      AT,
    },
  })
export default class AnnexBCG extends Vue {
    public spUserVerification = this.$store.state.spUserVerification;
    public state = this.$store.state;
    public activeIndex = 0;
    public atModel = false;

    public header = {
      atAdd: false,
      atAddSub: false,
      atEdit: false,
      atDelete: false,
      onWarning: false,
      onSuccess: true,
      reff: this.$refs,
      notification: '',
      title: '',
      code: '',
      loading: false,
      aipCode: '',
    };
    public form1(): VForm { return this.header.reff.form1 as VForm; }
    public form2(): VForm { return this.header.reff.form2 as VForm; }
    public form3(): VForm { return this.header.reff.form3 as VForm; }

    public enterKey() {

      if (this.atModel) {
        this.state.crudAT.search = this.state.AccountTitles[this.activeIndex].accountTitle;
        this.activeIndex = 0 ;
      }
      this.atModel = !this.atModel;

    }

    public downKey() {
      this.atModel = true;
      this.activeIndex++;
      this.activeIndex = (this.activeIndex >= this.state.AccountTitles.length) ?
        this.state.AccountTitles.length - 1 : this.activeIndex;
    }

    public upKey() {
      this.activeIndex--;
      this.atModel = true;
      this.activeIndex = (this.activeIndex < 0) ? 0 : this.activeIndex;
    }

    public dataClicked(obj: any) {
      const acnt = this.state.AccountTitles.filter((item: any) => item.account_id === obj.id);
      this.state.crudAT.search = acnt[0].accountTitle;
      this.activeIndex = obj.ind;
    }

    public keydown(e: any) {
      this.atModel = true;
    }

    public keydownTab() {
      this.atModel = false;
      this.state.crudAT.search = this.state.AccountTitles[this.activeIndex].accountTitle;
      this.activeIndex = 0 ;
    }

    public textBoxClicked() {
      this.atModel = true;
    }

    public async cancel() {
      this.header.title = '';
      this.header.code = '';
      this.header.onSuccess = true;
      this.header.loading = false;

      if (this.header.atAdd) { this.form1().resetValidation() ; }
      // (this.$refs.form1 as Vue & { resetValidation: () => boolean }).resetValidation();
      if (this.header.atAddSub) { this.form2().resetValidation() ; }
      if (this.header.atEdit) { this.form3().resetValidation() ; }

      this.header.atAdd = false;
      this.header.atAddSub = false;
      this.header.atEdit = false;
      this.header.atDelete = false;
      // this.header.onSuccess = false;
      // (this.$refs.form2 as Vue & { validate: () => boolean }).validate();

    }
    public async add() {
      // console.log('accountTitles/current', this.state.current);
      this.state.current.codeMthnode = this.state.current.code + '-' +  this.state.current.accId;
      const param = {
        parameters:
        {
            key: this.$store.state.apiKey,
            procedurename: 'spSetAccountTitles',
            motherNode: this.state.current.codeMthnode,
            title: this.header.title,
            code : this.header.code,
        },
      };
      this.header.loading = true;
      this.$store.state.api.executePost(this.$store, param, this.header);

    }
    public async addSub() {
      // console.log('accountTitles/current', this.state.current);
      this.state.current.codeMthnode = this.state.current.code + '-' +  this.state.current.accId;
      const param = {
        parameters:
        {
            key: this.$store.state.apiKey,
            procedurename: 'spSetAccountTitles_Sub',
            motherNode: this.state.crudAT.account_id,
            title: this.header.title,
            brgyID : this.state.current.brgyID,
        },
      };
      this.header.loading = true;
      this.$store.state.api.executePost(this.$store, param, this.header);
    }

    public async edit() {

      const param = {
        parameters:
        {
            key: this.$store.state.apiKey,
            procedurename: 'spGetAccountTitles_Edit',
            account_id: this.state.crudAT.account_id,
            title: this.header.title,
            code: this.header.code,
            brgyID : this.state.current.brgyID,
            aipCode: this.header.aipCode,
        },
      };
      this.header.loading = true;
      this.$store.state.api.executePost(this.$store, param, this.header);

    }

    public async editbtn() {
      this.header.title = this.state.crudAT.accountTitle;
      this.header.code = this.state.crudAT.accountCode;
    }

    public async deleteAT() {
      const param = {
        parameters:
        {
            key: this.$store.state.apiKey,
            procedurename: 'spGetAccountTitles_Delete',
            account_id: this.state.crudAT.account_id,
            brgyID : this.state.current.brgyID,
        },
      };
      this.header.loading = true;
      this.$store.state.api.executePost(this.$store, param, this.header);

    }


    @Watch ('state.crudAT.search')
    public onSearch( val: any, oldVal: any) {
      this.activeIndex = 0;
      // alert('AccountTitles/state.crudAT.search')
      // console.log('AccountTitles/state.crudAT.search', this.state.crudAT.search)
    }

    @Watch ('atModel')
    public stModelChanged( val: any, oldval: any) {
      // console.log('', val)
      if (!val) {
        this.state.AccountTitles = this.state.spGetAccountTitles;
      }
    }
}


import { render, staticRenderFns } from "./AnnexBCG.vue?vue&type=template&id=3086b13f&scoped=true&"
import script from "./ts/annex-bcg.ts?vue&type=script&scoped=true&lang=js&"
export * from "./ts/annex-bcg.ts?vue&type=script&scoped=true&lang=js&"
import style0 from "./scss/annex-bcg.scss?vue&type=style&index=0&id=3086b13f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3086b13f",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCol,VRow})

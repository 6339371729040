import { Vue, Component, Watch, Prop } from 'vue-property-decorator';

@Component
export default class AT extends Vue {
    @Prop(String) public search: string | undefined;
    @Prop(Number) public activeIndex: 0 | undefined;
    public spUserVerification = this.$store.state.spUserVerification;
    public get mActiveIndex() {
        return (this.activeIndex === undefined) ? 0 : this.activeIndex;
    }
    public state = this.$store.state;
    public position = 0;

    public headers = [
        {name: 'account_name', text: 'Account Title1', visible: true, width: 500},
        {name: 'pastyearActual', text: 'Pastyear Actual', visible: true, width: 100},
        {name: 'firstSemActual', text: 'first Sem Actual', visible: true, width: 100},
        {name: 'secondSemEsimate', text: 'second Sem Esimate', visible: true, width: 100},
        {name: 'BudgetyearProposed', text: 'Budgetyear Proposed', visible: true, width: 100},
    ];

    public mounted() {
        alert('AT spGetAccountTitles mounted');
        // console.log('AT spGetAccountTitles mounted');
        this.$store.state.api.executeGet(this.$store,
            'procedurename=spGetAccountTitles&motherNode=108&brgyID=44&yr=2018');
    }

    public onscroll() {
        return '';
    }
    public dataClicked(obj: any) {
        this.$emit('dataClicked', obj);
    }

    @Watch ('search')
    public onspGetAccountTitles( val: any, oldVal: any) {
        const data = this.state.spGetAccountTitles.filter((a: any) =>
            a.account_name.toLowerCase().includes(val.toLowerCase()));
        this.state.AccountTitles = data;
    }
    @Watch ('activeIndex')
    public onactiveIndex( val: any, oldVal: any) {
        const index = (this.activeIndex === undefined)  ? 0 : this.activeIndex;

        let obj: HTMLElement  = document.createElement('div');

        const elmnt = document.getElementById('boxid');
        obj = (elmnt === null) ? obj : elmnt;

        const height = (elmnt === null) ? 0 : elmnt.clientHeight;
        obj.scrollTop = obj.scrollHeight;
        const max = obj.scrollTop;
        obj.scrollTop = 0;
        const unit = max / this.state.AccountTitles.length;
        const yTop = (index + 2) * unit;
        if (yTop > height) {
            obj.scrollTop = yTop;
        }

        // console.log('activeIndex', index);
        // console.log('height',  height);
        // console.log('elmnt', max);
        // console.log('AccountTitles', this.state.AccountTitles.length);


    }
}


<template >
    <div >
        <div class="topContainer" ref="el"  @click="click()" >
            <div class="mcontainer">
                <div class="header"> 
                    <slot name="header"></slot>  
                    <div class="btn" >  <v-icon class="iconic" >mdi-chevron-up</v-icon> </div>
                </div>
            </div>    
        </div>    

        <div flat class="mcontent mopen" ref="cn">
            <slot name="content"></slot>
        </div>  
      
    </div>
</template>

<style scoped>
    .mcontainer {
        width: 100%;
        height: 100%;
        margin:auto;
        position: relative; 
        /* padding-bottom: 10px;
        padding-top: 10px;
        padding-left: 5px; */
        border: 1px solid;
        border-color:  rgba(53, 53, 53, 0.1);;
        /* background-color:  rgba(255, 255, 255, 0.1); */
    }
    .mcontainer:hover{
        cursor: pointer;
        background-color: rgba(255, 255, 255, 0.1);
        transition: all 0.4s ease 0s;
    }
    .btn{
        /* position:relative; */
        float: right;
        margin-right: 18px;
        /* top: -35px; */
        
        color:orange;
    }
    .header{
        position: relative;
        /* float: left; */
        margin-top: 10px;
        margin-bottom: 10px;
        margin-left: 5px;
        /* float:left; */
        color: orange;
        
    }

    .down{
        transform: rotate(0deg);
        transition: transform 1s linear;
    }

    .up{
        transform: rotate(-180deg);
        transition: transform 1s linear;
    }

    .mclose {
        max-height: 0;
        transition: max-height .25s ease-out;
        overflow: hidden;
    }
    .mopen {
        max-height: 1000px;
    }
  
</style>
<script>
export default {
    data() {
        return {
            open: true,
        };
    },
    methods: {
        click() {
            // console.log("Accordion this", this.$refs.el);
            const el = this.$refs.el.querySelector('.iconic');
            const content = this.$refs.cn;
            this.open = !this.open;
            // console.log(content.offsetHeight);
            if (this.open) {
                el.classList.remove('up');
                el.classList.add('down');
                content.classList.remove('mclose');
                content.classList.add('mopen');
            } else {
                el.classList.remove('down');
                el.classList.add('up');
                content.classList.remove('mopen');
                content.classList.add('mclose');

            }
        },
    },
};
</script>
import { Vue, Component } from 'vue-property-decorator';
import DataCom from '../components/DataCom.vue';
import Report from '../components/Report.vue';
import Status from '../components/Status.vue';
@Component({
    components: {
        DataCom,
        Report,
        Status,
    },
})
export default class AnnexE extends Vue {
    public spUserVerification = this.$store.state.spUserVerification;
    public state = this.$store.state;
    public mounted() {
        this.state.reportDetails = {
            name: 'Annex E',
            formNumber: 'BARANAGAY BUDGET PREPARATION FORM No. 3',
            title: 'PLANTILLA OF PERSONNEL, FY ' + this.state.current.budgetYear,
            subtitle: '',
            cy: 'CY-' + this.state.current.budgetYear,
            barangay: 'Barangay ' +
                    this.state.current.brgy_name.toLowerCase().replace(/^\w/, (c: any) => c.toUpperCase()),
            city: 'City of Davao',
            classification: 'GENERAL FUND',
            capitan: this.state.current.captain,
            secretary: this.state.current.secretary,
            treasurer: this.state.current.treasurer,
          };

        this.$store.state.current.header = 'AnnexE';
        this.$store.state.current.status = this.$store.state.status.annexE;
        this.$store.commit('loading', true );
        this.$store.state.api.executeGet(this.$store,
            'procedurename=spGet_AnnexEReport&brgy_id=' + this.state.current.brgyID +
            '&year=' +  this.state.current.budgetYear, this);


    }

    public clear() {
        // this.$refs.dataCom.clear();
        (this.$refs.dataCom as Vue & { clear: () => boolean }).clear();
    }
    public deleteRecord(headers: any) {
        const field = (fieldname: string) => headers.filter((a: any) => a.value === fieldname)[0].model;
        const param = {
            parameters:
            {
                key: this.$store.state.apiKey,
                procedurename: 'spdeleteAnnexEdetails',
                personnelscheddetailsID: field('personnelscheddetailsid'),
            },
        };

        this.$store.commit('loading', true );
        this.$store.state.api.executePost(this.$store, param, this);
    }
    public save(headers: any) {
        const field = (fieldname: string) => headers.filter((a: any) => a.value === fieldname)[0].model;

        const id = (position: string) =>
            this.state.titleposition.filter((a: any) =>
            a.position === position)[0].PositionID;


        const idGrade = (gradestep: string) =>
            this.state.gradeStep.filter((a: any) =>
            a.gradestep === gradestep)[0].gradestepID;
        // console.log('annexE/headers',  headers);
        const param = {
            parameters:
            {
                key: this.$store.state.apiKey,
                procedurename: 'spAnnexEUpdate',
                brgy_id: this.state.current.brgyID,
                budgetyear: this.state.current.budgetYear,
                personnelscheddetailsid: field('personnelscheddetailsid'),
                position: id( field('position')),
                incumbent: field('incumbent'),
                gradestep: idGrade(field('gradestep')),
                amountApproved: field('amountApproved'),
                amountAuthorized: field('amountAuthorized'),
                Year:  this.state.current.budgetYear,
            },
        };

        // console.log('annexE/param', param);
        this.$store.commit('loading', true );
        this.$store.state.api.executePost(this.$store, param, this);
    }
}


import { Vue, Component } from 'vue-property-decorator';


@Component
export default class UserVerification extends Vue {
    public objHandler = {loading: true, searhItems: [], email: '', password: '', repeatPassword: '', code: '',  error: '', success: '', router: {}, store: this.$store, barangayName: '' };
    public fileData = {baseLen: 0, data: '', maxLimit: 2048, progress: 0, unit: 0, onActive: false, requestID: 0 };
    public valid = false;
    public username = this.getUserName;
    public spUserVerification = this.$store.state.spUserVerification;
    public barangayName = '';
    public imageUrl = '';
    public files: [] = [];
    public imageFile: any = {};
    public mounted() {
        this.objHandler.router = this.$router;
        this.$store.state.api.executeGet(this.$store, 'procedurename=brgyuser.spGetBarangayList', this.objHandler);
    }

    public get getUserName(): string {
        return  this.$store.state.user.username;
    }

    public submit() {
        try {

            const Param = 'procedurename=spVerificationRequest&username=' + this.spUserVerification.username +  '&email=' + this.spUserVerification.email +
            '&lname=' + this.spUserVerification.lastname + '&fname=' +  this.spUserVerification.firstname +
            '&brgy_name=' + this.barangayName + '&brgyID_Picture=' + this.fileData.requestID ;
            // console.log(Param);
            this.$store.state.api.executeGet(this.$store, Param, this.objHandler);

            return '';
        } catch (err) {
            // console.log (err);
            return '';
        }
    }
    public onFilePicked(e: any) {

        const fileList = this.files;
        const store = this.$store;
        const fileData = this.fileData;

        fileList.map((file: any) => {
            const reader = new FileReader();
            reader.onload = ((theFile) => {
                    return (evt: any) => {
                      const binaryData = evt.target.result;
                      fileData.data = window.btoa(binaryData);
                      const apikey = '?key=' + store.state.apiKey + '&';

                      const segmentParam = 'procedurename=spUploadSegment&requestID=' +
                        '0000000' +  '&segmentIndex=' + '0000'
                        + '&segmentData=';

                      fileData.baseLen = fileData.maxLimit - (segmentParam.length + apikey.length);
                      const initialSegment = Math.floor(fileData.data.length / fileData.baseLen);

                      const addOne = ((fileData.data.length - (initialSegment * fileData.baseLen )) === 0) ? 0 : 1;

                      const segmentCount = initialSegment + addOne;

                      fileData.unit = 100 / segmentCount;
                      fileData.onActive = true;

                      const param = 'procedurename=spUploadRequest&username=' +
                        store.state.spUserVerification.username +
                        '&filename=' + file.name +
                        '&segmentCount=' + segmentCount;

                      this.$store.state.api.executeGet(store, param, fileData);

                    };
                  })(file[0]);
            reader.readAsBinaryString(file);
        });


    }
}


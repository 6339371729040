var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"caption"},on),[_vm._v(" "+_vm._s(_vm.store.state.annexCategory.title)+" "),_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-menu-down ")])],1)]}}])},[_c('v-card',[_c('v-list',{staticClass:"caption",attrs:{"dense":""}},[_c('v-list-item-group',{attrs:{"color":"primary"}},[_c('v-list-item',{on:{"click":function($event){return _vm.dataClicked('annexCategory', {header:'ProjectActive', headerTitle:'Projects', alterHeader: _vm.store.state.annexCategory.alterHeader, title:'Personal Services', subView: 'projects', code: _vm.store.state.current.code, accId: 'ACC-00407', fppid: _vm.store.state.current.fppid} )}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-file-eye")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Personal Services ")])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.dataClicked('annexCategory', {
                    header:'ProjectActive', 
                    headerTitle:'Projects', 
                    alterHeader: _vm.store.state.annexCategory.alterHeader, 
                    title:'Maintenance and Other Operating Expense', 
                    subView: 'projects', 
                    code: _vm.store.state.current.code, 
                    accId: 'ACC-0435', 
                    fppid: _vm.store.state.current.fppid})}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-gold")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Maintenance and Other Operating Expense ")])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.dataClicked('annexCategory', {
                    header:'ProjectActive', 
                    headerTitle:'Projects', 
                    alterHeader: _vm.store.state.annexCategory.alterHeader, 
                    title:'Infrastructure Projects', 
                    subView: 'projects', 
                    code: _vm.store.state.current.code, 
                    accId: 'ACC-921', 
                    fppid: _vm.store.state.current.fppid})}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-gold")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Infrastructure Projects ")])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.dataClicked('annexCategory', {header:'ProjectActive',headerTitle:'Projects', alterHeader: _vm.store.state.annexCategory.alterHeader, title:'Capital Outlay', subView: 'projects', code: _vm.store.state.current.code, accId: 'ACC-108', fppid: _vm.store.state.current.fppid})}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-gold")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Capital Outlay ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import { Vue, Component } from 'vue-property-decorator';
import LeftNav from '../left-nav/LeftNav.vue';
@Component({
    components: {
      LeftNav,
    },
  })
export default class Header extends Vue {
    public state = this.$store.state;
    public logOut() {
        this.$store.commit('userHasLoggedOut');
        this.$router.push('/home');
    }
    public barangay() {
        // this.$store.commit('navMenuLeft', this.$store.state.navBarangayItems);

        if (this.$route.path !== '/barangayList/' + this.state.current.budgetYear ) {
            this.$router.push('/barangayList/' + this.state.current.budgetYear);
        }
    }
    public reports() {
        this.$store.commit('navMenuLeft', this.$store.state.navReportItems);
    }
    public forms() {
        this.$store.commit('navMenuLeft', this.$store.state.navFormItems);
        this.$router.push('/annexbcg');
    }
    public yearMenu() {
        this.$store.commit('navMenuLeft', this.$store.state.navBarangayItems);
    }
    public drawer() {
        this.$store.commit('drawer');
    }
    public request() {
        if (this.$route.path !== '/userverificationapproval' ) {
            this.$router.push('/userverificationapproval');
         }
    }

}


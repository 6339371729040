import { Vue, Component } from 'vue-property-decorator';
import DataCom from '../components/DataCom.vue';
import Report from '../components/Report.vue';
import Status from '../components/Status.vue';
@Component({
    components: {
        DataCom,
        Report,
        Status,
    },
})
export default class AnnexF extends Vue {
    public spUserVerification = this.$store.state.spUserVerification;
    public state = this.$store.state;
    public mounted() {
        this.state.reportDetails = {
            name: 'Annex F',
            formNumber: 'BARANAGAY BUDGET PREPARATION FORM No. 4',
            title: 'STATEMENT OF INDEBTEDNESS, FY ' + this.state.current.budgetYear,
            subtitle: '',
            cy: 'CY-' + this.state.current.budgetYear,
            barangay: 'Barangay ' +
                    this.state.current.brgy_name.toLowerCase().replace(/^\w/, (c: any) => c.toUpperCase()),
            city: 'City of Davao',
            classification: 'GENERAL FUND',
            capitan: this.state.current.captain,
            secretary: this.state.current.secretary,
            treasurer: this.state.current.treasurer,
          };

        this.$store.state.current.header = 'AnnexF';
        this.$store.state.current.status = this.$store.state.status.annexF;
        this.$store.commit('loading', true );
        this.$store.state.api.executeGet(this.$store,
            'procedurename=spGet_AnnexFReport&brgy_id=' + this.state.current.brgyID +
            '&year=' +  this.state.current.budgetYear);

    }

    public clear() {
        // this.$refs.dataCom.clear();
        (this.$refs.dataCom as Vue & { clear: () => boolean }).clear();
    }
    public deleteRecord(headers: any) {
        const field = (fieldname: string) => headers.filter((a: any) => a.value === fieldname)[0].model;
        const param = {
            parameters:
            {
                key: this.$store.state.apiKey,
                procedurename: 'spdeleteAnnexFdetails',
                personnelscheddetailsID: field('SOIdetails_id'),
            },
        };

        this.$store.commit('loading', true );
        this.$store.state.api.executePost(this.$store, param, this);
    }
    public save(headers: any) {
        const field = (fieldname: string) => headers.filter((a: any) => a.value === fieldname)[0].model;
        const param = {
            parameters:
            {
                key: this.$store.state.apiKey,
                procedurename: 'spAnnexFUpdate',
                brgy_id: this.state.current.brgyID,
                year: this.state.current.budgetYear,
                SOIdetails_id: field('SOIdetails_id'),
                creditor: field('creditor'),
                date_contracted: field('date_contracted'),
                term: field('term'),
                purpose: field('purpose'),
                principal_amount: field('principal_amount'),
                principal_previous: field('principal_previous'),
                interest_previous: field('interest_previous'),
                principal_due: field('principal_due'),
                interest_due: field('interest_due'),
            },
        };
        this.$store.commit('loading', true );
        this.$store.state.api.executePost(this.$store, param, this);
    }
}


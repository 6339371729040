var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-toolbar',[_c('v-toolbar-title',[_vm._v("Barangay Budget Completion Status")])],1),_c('v-col',{attrs:{"xs":"12","sm":"12","md":"12","xl":"12"}},[_c('v-card',{staticClass:"mr-5 mt-0"},[_c('v-data-table',{staticClass:"elevation-1 reportable",attrs:{"headers":_vm.headers,"items":_vm.state.spGetBarangayStatus_alter,"loading":_vm.state.loading,"search":_vm.search},on:{"click:row":_vm.tableClick},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',[_c('v-autocomplete',{staticClass:"mt-1",attrs:{"items":_vm.barangayItems,"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},on:{"click":function($event){return _vm.reload()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"ml-2",attrs:{"fab":"","x-small":""},on:{"click":function($event){return _vm.reload()}}},[_c('v-icon',{attrs:{"small":"","dark":""}},[_vm._v("mdi-reload")])],1),_c('v-spacer'),_c('v-toolbar-items',[_c('AnnexMenu',{attrs:{"label":"Annex A"},on:{"statusChanged":function($event){return _vm.statusChanged($event, 'A')}}}),_c('AnnexMenu',{attrs:{"label":"Annex BCG"},on:{"statusChanged":function($event){return _vm.statusChanged($event, 'BCG')}}}),_c('AnnexMenu',{attrs:{"label":"Annex D"},on:{"statusChanged":function($event){return _vm.statusChanged($event, 'D')}}}),_c('AnnexMenu',{attrs:{"label":"Annex E"},on:{"statusChanged":function($event){return _vm.statusChanged($event, 'E')}}}),_c('AnnexMenu',{attrs:{"label":"Annex F"},on:{"statusChanged":function($event){return _vm.statusChanged($event, 'F')}}})],1)],1)]},proxy:true},{key:"item.AnnexA",fn:function(ref){
var item = ref.item;
return [(item.AnnexA!=='')?_c('v-chip',{attrs:{"color":_vm.getColor(item.AnnexA),"dark":""},on:{"click":function($event){return _vm.chipsClick(item, 'annexa')}}},[_vm._v(_vm._s(item.AnnexA))]):_vm._e()]}},{key:"item.AnnexBCG",fn:function(ref){
var item = ref.item;
return [(item.AnnexBCG!=='')?_c('v-chip',{attrs:{"color":_vm.getColor(item.AnnexBCG),"dark":""},on:{"click":function($event){return _vm.chipsClick(item, 'annexbcg')}}},[_vm._v(_vm._s(item.AnnexBCG))]):_vm._e()]}},{key:"item.AnnexD",fn:function(ref){
var item = ref.item;
return [(item.AnnexD!=='')?_c('v-chip',{attrs:{"color":_vm.getColor(item.AnnexD),"dark":""},on:{"click":function($event){return _vm.chipsClick(item, 'annexd')}}},[_vm._v(_vm._s(item.AnnexD))]):_vm._e()]}},{key:"item.AnnexE",fn:function(ref){
var item = ref.item;
return [(item.AnnexE!=='')?_c('v-chip',{attrs:{"color":_vm.getColor(item.AnnexE),"dark":""},on:{"click":function($event){return _vm.chipsClick(item, 'annexe')}}},[_vm._v(_vm._s(item.AnnexE))]):_vm._e()]}},{key:"item.AnnexF",fn:function(ref){
var item = ref.item;
return [(item.AnnexF!=='')?_c('v-chip',{attrs:{"color":_vm.getColor(item.AnnexF),"dark":""},on:{"click":function($event){return _vm.chipsClick(item, 'annexf')}}},[_vm._v(_vm._s(item.AnnexF))]):_vm._e()]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {},
    router: {},
    axios: {},
    baseUrl: '',
    apiKey: '',
    api: {},
    Draw: {},
    apiCalls: [{}],
    notLogin: true,
    onLogin: false,
    current: {
      brgyID: 0,
      brgy_name: '',
      brgy_district: '',
      captain: '',
      treasurer: '',
      secretary: '',
      budgetYear: 2018,
      codeMthnode: '',
      code: '',
      fppid: '',
      accId: '0',
      aipCode: '',
      budgetTypeID: '1',
      projSelected: '',
      header: '',
      status: 0,
      annexBCG: {
        type: 'IncomeStatement',
        header: [
          {
              text: 'Particulars',
              align: 'left',
              sortable: false,
              value: 'Title',
              i: true, b: true, c: true, g: true,
          },
        ],
        body: {},
        printPreview: false,
      },
    },
    status: {
      annexA : 0,
      annexBCG : 0,
      annexD: 0,
      annexE: 0,
      annexF: 0,
    },
    spUserVerification: {status: ''},
    spGetBarangayList: {},
    annexBCG: [
      {Title: 'I. Beginning Balance', Code: '', t2: '', t9: '', t10: '', t11: '', t12: '', t17: '', t8: ''},
      {Title: 'II. Income', Code: '', t2: '', t9: '', t10: '', t11: '', t12: '', t17: '', t8: ''},
      {Title: 'III. Expenditure', Code: '', t2: '', t9: '', t10: '', t11: '', t12: '', t17: '', t8: ''},
    ],
    annexBCG_Base: [
      {Title: 'I. Beginning Balance', Code: '', t2: '', t9: '', t10: '', t11: '', t12: '', t17: '', t8: ''},
      {Title: 'II. Income', Code: '', t2: '', t9: '', t10: '', t11: '', t12: '', t17: '', t8: ''},
      {Title: 'III. Expenditure', Code: '', t2: '', t9: '', t10: '', t11: '', t12: '', t17: '', t8: ''},
    ],
    annexBCG_empty: [
      {Title: 'I. Beginning Balance', Code: '', t2: '', t9: '', t10: '', t11: '', t12: '', t17: '', t8: ''},
      {Title: 'II. Income', Code: '', t2: '', t9: '', t10: '', t11: '', t12: '', t17: '', t8: ''},
      {Title: 'III. Expenditure', Code: '', t2: '', t9: '', t10: '', t11: '', t12: '', t17: '', t8: ''},
    ],
    navMenuLeft: {},
    navFormItems: {},
    navReportItems: {},
    navBarangayItems: {},
    drawer: true,
    loading: false,
    annexCategory: {
      header: 'Select Particular Category' ,
      headerTitle: 'Select Particular Category' ,
      title: '',
      alterHeader: '',
      code: '',
      fppid: ''},
    bcg: {
       balance : false ,
       income : false ,
       expenditure : false ,
       spa : false ,
       genAdd : false ,
       ps : false ,
       mooe : false ,
       co : false ,
       infra : false ,
       projects : false ,
       accntActivated : false ,
       projectOpen : false,
       projectActive : false,
    },
    acntNode: {
      balance : false ,
      income : 312 ,
      expenditure : false ,
      spa : 540 ,
      genAdd : false ,
      ps : false ,
      mooe : false ,
      co : false ,
      projects : false ,
   },
   spGetAccountTitles : {},
   AccountTitles : {},
   crudAT : {
    account_id: 0,
    accountTitle: '',
    accountCode: '',
    pastyearActual: '',
    firstSemActual: '',
    secondSemEsimate: '',
    BudgetyearProposed: '',
    expected_results: '',
    performance_indicator: '',
    Curring: '',
    search: '',
   },
   Get_AnnexAReport : {},
   headerAnnexA: {},
   Get_AnnexDReport : {},
   headerAnnexD: {},
   Get_AnnexEReport : {},
   headerAnnexE: {},
   Get_AnnexFReport : {},
   headerAnnexF: {},
   titleposition: {},
   gradeStep: {},
   spGetProjects: {},
   spGetBarangayStatus: [
      {
      BudgetYear: '',
      brgy_district: '',
      brgy_name: '',
      AnnexA:  '',
      AnnexBCG: '',
      AnnexD: '',
      AnnexE: '',
      AnnexF: '',
      },
    ],
    spGetBarangayStatus_alter: [
      {
      BudgetYear: '',
      brgy_district: '',
      brgy_name: '',
      AnnexA:  '',
      AnnexBCG: '',
      AnnexD: '',
      AnnexE: '',
      AnnexF: '',
      },
    ],
   dialog : {
    save: false,
    delete: false,
   },
   reportDetails: {},
  },
  mutations: {
    spGetBarangayStatus: (state, userdata) => {
      state.spGetBarangayStatus = userdata;
      state.spGetBarangayStatus_alter = JSON.parse(JSON.stringify(userdata));
    },
    spGetProjects: (state, userdata) => {
      state.spGetProjects = userdata;
    },
    gradeStep: (state, userdata) => {
      state.gradeStep = userdata;
    },
    titleposition: (state, userdata) => {
      state.titleposition = userdata;
    },
    Get_AnnexFReport: (state, userdata) => {
      state.Get_AnnexFReport = userdata;
      state.loading = false;
    },
    Get_AnnexEReport: (state, userdata) => {
      state.Get_AnnexEReport = userdata;
      state.loading = false;
    },
    Get_AnnexDReport: (state, userdata) => {
      state.Get_AnnexDReport = userdata;
      state.loading = false;
    },
    spGetAccountTitles : (state, userdata) => {
      state.spGetAccountTitles  = userdata;
      state.AccountTitles = JSON.parse(JSON.stringify(userdata));
      // Whenever the account titles get re loaded, the input values will be set on empty.
      const mcrudAT = {
        account_id: 0,
        accountTitle: '',
        accountCode: '',
        pastyearActual: '',
        firstSemActual: '',
        secondSemEsimate: '',
        BudgetyearProposed: '',
        expected_results: '',
        performance_indicator: '',
        Curring: '',
        search: '',
       };
      state.crudAT = mcrudAT;
      //  console.log ('Store/state.crudAT', state.crudAT );

    },
    annexCategory: (state, userdata) => {
      state.annexCategory = userdata;
      state.bcg.balance = false;
      state.bcg.income = false;
      state.bcg.expenditure = false;
      state.bcg.spa = false;
      state.bcg.genAdd = false;
      state.bcg.ps = false;
      state.bcg.mooe = false;
      state.bcg.co = false;
      state.bcg.infra = false;
      state.bcg.projects = false;
      state.bcg.accntActivated = false;
      state.bcg.projectOpen = false;
      state.bcg.projectActive = false;
      state.current.code = userdata.code;
      state.current.fppid = userdata.fppid;
      state.current.accId = '0';

      // console.log('store/userdata', userdata);
      // console.log('store/.annexCategory', state.annexCategory);
      // console.log('store/.current.code', state.current.code);
      switch (userdata.header) {
        case 'View Annex B,C, and G':
          break;
        case 'Expenditure':
          break;
        case 'Current Operating Expenditure':
          break;
        case 'General Administration':
          break;
        case 'Beginning Balance':
            state.bcg.balance = true;
            break;
        case 'Income':
            state.bcg.income = true;
            state.bcg.accntActivated = true;
            break;
        case 'Expenditure':
            state.bcg.expenditure = true;
            state.bcg.accntActivated = true;
            break;
        case 'Special Purpose Appropriation':
            state.bcg.spa = true;
            state.bcg.expenditure = true;
            state.bcg.accntActivated = true;
            break;
        case 'General Administration':
            state.bcg.genAdd = true;
            state.bcg.expenditure = true;
            break;
        case 'Personal Services':
            state.bcg.ps = true;
            state.bcg.expenditure = true;
            state.bcg.accntActivated = true;
            break;
        case 'Maintenance and Other Operating Expenses':
            state.bcg.mooe = true;
            state.bcg.expenditure = true;
            state.bcg.accntActivated = true;
            break;
        case 'Capital Outlay':
            state.bcg.co = true;
            state.bcg.expenditure = true;
            state.bcg.accntActivated = true;
            break;
        case 'Infrastruture Project':
            state.bcg.infra = true;
            state.bcg.expenditure = true;
            state.bcg.accntActivated = true;
            break;
        case 'Projects':
            state.bcg.projects = true;
            state.bcg.expenditure = true;
            state.current.projSelected = '';
            break;
        case 'ProjectOpen':
            state.bcg.projectOpen = true;
            state.bcg.expenditure = true;
            state.bcg.projects = true;
            state.current.projSelected = state.annexCategory.alterHeader;
            break;
        case 'ProjectActive':
            state.bcg.projectActive = true;
            state.bcg.projectOpen = true;
            state.bcg.expenditure = true;
            state.bcg.projects = true;
            state.bcg.accntActivated = true;
            state.current.accId = userdata.accId;
            state.current.projSelected = state.annexCategory.alterHeader;
            break;
        default:
            state.bcg.projectOpen = true;
            state.bcg.expenditure = true;
            state.bcg.projects = true;
            break;
      }
    },
    loading: (state, userdata) => {
      state.loading = userdata;
    },
    hidedrawer: (state) => {
      state.drawer = false ;
    },
    showdrawer: (state) => {
      state.drawer = true ;
    },
    drawer: (state) => {
      state.drawer = !state.drawer ;
    },
    objHandler: (state, obj) => {
      state.router = obj.router;
      state.axios = obj.axios;
      state.baseUrl = obj.baseUrl;
      state.apiKey = obj.apiKey;
      state.api = obj.api;
      state.Draw = obj.Draw;
      state.navMenuLeft = obj.navMenuLeft;
      state.navFormItems = obj.navFormItems;
      state.navReportItems = obj.navReportItems;
      state.navBarangayItems = obj.navBarangayItems;
      state.headerAnnexA = obj.headerAnnexA;
      state.headerAnnexD = obj.headerAnnexD;
      state.headerAnnexE = obj.headerAnnexE;
      state.headerAnnexF = obj.headerAnnexF;
      state.Get_AnnexAReport = obj.defaultAnnexA;
      state.Get_AnnexDReport = obj.defaultAnnexD;
      state.Get_AnnexEReport = obj.defaultAnnexE;
      state.Get_AnnexFReport = obj.defaultAnnexF;

    },
    spGetBarangayList: (state, userdata) => {
      state.spGetBarangayList = userdata;
    },
    updateUser: (state, userdata) => {
      state.user = userdata;
    },
    spUserVerification: (state, userdata) => {
      state.spUserVerification = userdata;
      state.current.brgyID = userdata.brgy_ID;
      state.current.captain = userdata.captain;
      state.current.secretary = userdata.secretary;
      state.current.treasurer = userdata.treasurer;
      const res =  state.spUserVerification.status.split(',');
      state.status.annexA = parseInt(res[0], 10);
      state.status.annexBCG = parseInt(res[1], 10);
      state.status.annexD = parseInt(res[2], 10);
      state.status.annexE = parseInt(res[3], 10);
      state.status.annexF = parseInt(res[4], 10);



    },
    annexBCG: (state, userdata) => {
      state.annexBCG_Base = JSON.parse(JSON.stringify(userdata));
      state.annexBCG = userdata;
      state.loading = false;
      state.crudAT.accountTitle = '';
      state.crudAT.accountCode = '';
      state.crudAT.account_id = 0;
      state.crudAT.pastyearActual = '';
      state.crudAT.firstSemActual = '';
      state.crudAT.secondSemEsimate = '';
      state.crudAT.BudgetyearProposed = '';

    },
    Get_AnnexAReport: (state, userdata) => {
      state.Get_AnnexAReport = userdata;
      state.loading = false;
    },
    navMenuLeft: (state, userdata) => {
      state.navMenuLeft = userdata;
    },
    userHasLoggedIn: (state) => {
      state.notLogin = false;
      state.onLogin = true;
    },
    userHasLoggedOut: (state) => {
      state.notLogin = true;
      state.onLogin = false;
      state.spUserVerification = {status: ''};
    },
  },
  getters: {
    getHeadersAnnexBCG: (state) => {

      const mHeader = [
        {
            text: 'Particulars',
            align: 'left',
            sortable: false,
            value: 'Title',
            i: true, b: true, c: true, g: true,
            style: {
              pLeft: 1,
              pRight: 1,
              pTop: 1,
              pBottom: 2,
              fontsize: 10,
              fontname: 'times',
              fontstyle: 'normal',
              widthstyle: 'fixed',
              heightstyle: 'auto',
              childChar: '',
              childSpacing: 1,
              width: 100,
              height: 50,
              align: 'left',
              valign: 'bottom',
            },
        },
        {
          text: 'AIP Reference Code',
          align: 'left',
          sortable: false,
          value: 'AIPCode',
          i: false, b: false, c: true, g: false,
          style: {
            pLeft: 1,
            pRight: 1,
            pTop: 1,
            pBottom: 2,
            fontsize: 10,
            fontname: 'times',
            fontstyle: 'normal',
            widthstyle: 'fixed',
            heightstyle: 'auto',
            childChar: '   ',
            childSpacing: 1,
            width: 20,
            height: 50,
            align: 'left',
            valign: 'bottom',
          },
        },
        {
            text: 'Account Code',
            align: 'left',
            sortable: false,
            value: 't2',
            i: true, b: true, c: true, g: true,
            style: {
              pLeft: 1,
              pRight: 1,
              pTop: 1,
              pBottom: 2,
              fontsize: 10,
              fontname: 'times',
              fontstyle: 'normal',
              widthstyle: 'fixed',
              heightstyle: 'auto',
              childChar: '   ',
              childSpacing: 1,
              width: 20,
              height: 50,
              align: 'center',
              valign: 'bottom',
            },
        },
        {
            text: 'Income Classification',
            align: 'left',
            sortable: false,
            value: 't9',
            i: false, b: true, c: false, g: false,
            style: {
              pLeft: 1,
              pRight: 1,
              pTop: 1,
              pBottom: 2,
              fontsize: 10,
              fontname: 'times',
              fontstyle: 'normal',
              widthstyle: 'fixed',
              heightstyle: 'auto',
              childChar: '   ',
              childSpacing: 1,
              width: 35,
              height: 50,
              align: 'center',
              valign: 'bottom',
            },
        },
        {
            text: 'CY ' + (state.current.budgetYear - 2),
            align: 'left',
            sortable: false,
            value: 't10',
            i: true, b: true, c: false, g: false,
            style: {
              pLeft: 1,
              pRight: 3,
              pTop: 1,
              pBottom: 2,
              fontsize: 10,
              fontname: 'times',
              fontstyle: 'normal',
              widthstyle: 'fixed',
              heightstyle: 'auto',
              childChar: '   ',
              childSpacing: 1,
              width: 35,
              height: 50,
              align: 'right',
              valign: 'bottom',
            },
        },
        {
            text: 'CY ' + (state.current.budgetYear - 1) + ' 1stSem',
            align: 'left',
            sortable: false,
            value: 't11',
            i: true, b: true, c: false, g: false,
            style: {
              pLeft: 1,
              pRight: 3,
              pTop: 1,
              pBottom: 2,
              fontsize: 10,
              fontname: 'times',
              fontstyle: 'normal',
              widthstyle: 'fixed',
              heightstyle: 'auto',
              childChar: '   ',
              childSpacing: 1,
              width: 35,
              height: 50,
              align: 'right',
              valign: 'bottom',
            },
        },
        {
            text: 'CY ' + (state.current.budgetYear - 1 ) + ' 2ndSem',
            align: 'left',
            sortable: false,
            value: 't12',
            i: true, b: true, c: false, g: false,
            style: {
              pLeft: 1,
              pRight: 3,
              pTop: 1,
              pBottom: 2,
              fontsize: 10,
              fontname: 'times',
              fontstyle: 'normal',
              widthstyle: 'fixed',
              heightstyle: 'auto',
              childChar: '   ',
              childSpacing: 1,
              width: 35,
              height: 50,
              align: 'right',
              valign: 'bottom',
            },
        },
        {
            text: 'Total',
            align: 'left',
            sortable: false,
            value: 't17',
            i: true, b: true, c: false, g: false,
            style: {
              pLeft: 1,
              pRight: 3,
              pTop: 1,
              pBottom: 2,
              fontsize: 10,
              fontname: 'times',
              fontstyle: 'normal',
              widthstyle: 'fixed',
              heightstyle: 'auto',
              childChar: '   ',
              childSpacing: 1,
              width: 35,
              height: 50,
              align: 'right',
              valign: 'bottom',
            },
        },
        {
            text: 'CY ' + (state.current.budgetYear) ,
            align: 'left',
            sortable: false,
            value: 't8',
            i: true, b: true, c: true, g: true,
            style: {
              pLeft: 1,
              pRight: 3,
              pTop: 1,
              pBottom: 2,
              fontsize: 10,
              fontname: 'times',
              fontstyle: 'normal',
              widthstyle: 'fixed',
              heightstyle: 'auto',
              childChar: '   ',
              childSpacing: 1,
              width: 35,
              height: 50,
              align: 'right',
              valign: 'bottom',
            },
        },
        {
          text: 'Expected Results' ,
          align: 'left',
          sortable: false,
          value: 'expected_results',
          i: false, b: false, c: true, g: true,
          style: {
            pLeft: 1,
            pRight: 1,
            pTop: 1,
            pBottom: 2,
            fontsize: 10,
            fontname: 'times',
            fontstyle: 'normal',
            widthstyle: 'fixed',
            heightstyle: 'auto',
            childChar: '   ',
            childSpacing: 1,
            width: 50,
            height: 50,
            align: 'left',
            valign: 'bottom',
          },
        },
        {
          text: 'Performance Indicator' ,
          align: 'left',
          sortable: false,
          value: 'Performance_Indicator',
          i: false, b: false, c: false, g: true,
          style: {
            pLeft: 1,
            pRight: 1,
            pTop: 1,
            pBottom: 2,
            fontsize: 10,
            fontname: 'times',
            fontstyle: 'normal',
            widthstyle: 'fixed',
            heightstyle: 'auto',
            childChar: '   ',
            childSpacing: 1,
            width: 50,
            height: 50,
            align: 'left',
            valign: 'bottom',
          },
        },
        {
            text: 'Actions ',
            align: 'right',
            sortable: false,
            value: 'action',
            visible: false,
            i: true, b: true, c: true, g: true,
        },
      ];
      let head: any ;
      let headDetails: any;
      switch (state.current.annexBCG.type) {
        case 'IncomeStatement':
            head = mHeader.filter((o) => o.i === true);
            headDetails = {
              name: 'Annex B',
              formNumber: 'Barangay Budget Preparation Form No. 1',
              title: 'BUDGET EXPENDITURES AND SOURCES OF FINANCING, FY ' + state.current.budgetYear,
              barangay: 'Barangay ' + state.current.brgy_name.toLowerCase().replace(/^\w/, (c: any) => c.toUpperCase()),
              city: 'City of Davao',
              classification: 'GENERAL FUND',
              appropriation: 'Appropriation as of ' +  (state.current.budgetYear - 1),
              capitan: state.current.captain,
              secretary: state.current.secretary,
              treasurer: state.current.treasurer,
            };
            break;
        case 'AnnexB' :
            head = mHeader.filter((o) => o.b === true);
            headDetails = {
              name: 'Annex B',
              formNumber: 'Barangay Budget Preparation Form No. 1',
              title: 'BUDGET EXPENDITURES AND SOURCES OF FINANCING, FY ' + state.current.budgetYear,
              barangay: 'Barangay ' + state.current.brgy_name.toLowerCase().replace(/^\w/, (c: any) => c.toUpperCase()),
              city: 'City of Davao',
              classification: 'GENERAL FUND',
              appropriation: 'Appropriation as of ' + (state.current.budgetYear - 1),
              capitan: state.current.captain,
              secretary: state.current.secretary,
              treasurer: state.current.treasurer,
            };
            break;
        case 'AnnexC' :
            head = mHeader.filter((o) => o.c === true);
            headDetails = {
              name: 'Annex C',
              formNumber: 'Barangay Budget Preparation Form No. 2',
              title: 'PROGRAMMED APPROPRIATION BY PPA, EXPENSE CLASS, ' +
                     'OBJECT OF EXPENDITURE AND EXPECTED RESULTS, FY '
                      + state.current.budgetYear,
              barangay: 'Barangay ' + state.current.brgy_name.toLowerCase().replace(/^\w/, (c: any) => c.toUpperCase()),
              city: 'City of Davao',
              classification: 'GENERAL FUND',
              capitan: state.current.captain,
              secretary: state.current.secretary,
              treasurer: state.current.treasurer,
            };
            break;
        case 'AnnexG' :
            head = mHeader.filter((o) => o.g === true);
            headDetails = {
              name: 'Annex G',
              formNumber: '',
              title: 'GENERAL FUND ANNUAL BUDGET',
              cy: 'CY-' + state.current.budgetYear,
              barangay: 'Barangay ' + state.current.brgy_name.toLowerCase().replace(/^\w/, (c: any) => c.toUpperCase()),
              city: 'City of Davao',
              classification: 'GENERAL FUND',
              capitan: state.current.captain,
              secretary: state.current.secretary,
              treasurer: state.current.treasurer,
            };
            break;
      }
      state.reportDetails = headDetails;
      // console.log('store/getters', head);
      // alert(state.current.status );
      if (state.current.status > 0) {
        // alert('hit');
        head = head.filter((m: any) => m.value !== 'action');
      }
      return head;
    },
    annexBCG: (state) => {

      state.annexBCG = JSON.parse(JSON.stringify(state.annexBCG_Base));
      switch (state.current.annexBCG.type) {
        case 'IncomeStatement':
          return state.annexBCG;
        case 'AnnexB' :
          return state.annexBCG;
        case 'AnnexC' :
            state.annexBCG = state.annexBCG_Base.filter((o: any) => o.Code.includes('FPP-22') === true);
            return state.annexBCG;
        case 'AnnexG' :
          return state.annexBCG;
      }
    },
  },
  actions: {
    setupAnnexBCG(context) {
      // console.log('store/actions',  context );
    },
  },
  modules: {
  },
});

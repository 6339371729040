import { Vue, Component, Prop } from 'vue-property-decorator';
import Field from '../NemTree/Field';
import TreeNode from '../NemTree/TreeNode';
import NemTree from '../NemTree/NemTree';
@Component
export default class TreeCell extends Vue {
    public get mNode() {
        const sourceTree: NemTree = new NemTree();
        const node: TreeNode  = new TreeNode(sourceTree);

        return this.Node ? this.Node : node;
    }
    @Prop(Number) public Level: number | undefined;
    @Prop(Number) public SeqNumber: number | undefined;
    @Prop(Boolean) public ShowToolbar: boolean  = true;
    @Prop(String) public Backgroundcolor: string  = this.mBackgroundcolor;
    @Prop(String) public ForeColor: string | undefined = 'orange';

    @Prop(Array) public Fields: Field[] | undefined;
    @Prop(Object) public Node: TreeNode| undefined;
    public boxSquareDimension: number  = 23;
    private dragActivated: boolean = false;
    private strtXaxis: number = 0;
    private selectedindex: number = 0;
    private originalwidth: number = 0;

    public mounted() {
        window.addEventListener('mouseup', this.stopDrag);
        window.addEventListener('mousemove', this.doDrag);
    }
    public doDrag(e: any) {

        if (this.dragActivated) {
            // console.log (e);
            // console.log (this.mNode);
            const nd = this.mNode;
            // nd.fields[3].value = "X: " +  (this.originalwidth + e.clientX - this.strtXaxis);
            nd.fields[this.selectedindex].width = (this.originalwidth + e.clientX - this.strtXaxis);

            // if (this.Fields){
            //     this.Fields[3].value = e.clientX;
            //     this.Fields[this.selectedindex].width = this.originalwidth + (e.ClientX - this.strtXaxis);
            // }

        }

    }
    public stopDrag() {
        this.dragActivated = false;
    }
    public startDrag(e: any, index: number) {
        this.dragActivated = true;
        const nd = this.mNode;

        this.originalwidth = nd.fields[index].width;
        this.strtXaxis = e.clientX;
        this.selectedindex = index;
        // nd.fields[3].value = "X axis: " + this.strtXaxis;

    }
    private totalWidth(): number {
        // return 10000;
        // console.log('totalFieldWidth', this.mNode.totalFieldWidth);

        return this.mNode.totalFieldWidth + (this.boxSquareDimension * 4)
        + (this.mNode.fields.length * 6) + this.mNode.fields.length;
    }
    private get mBackgroundcolor(): string {
        return this.Backgroundcolor === '' ? 'none' : this.Backgroundcolor;
    }
}

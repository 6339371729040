import { Vue, Component } from 'vue-property-decorator';

@Component
export default class SelectCategory extends Vue {
    public store = this.$store;
    public state = this.$store.state;
    public dataClicked( name: any, userdata: any) {
        this.store.commit(name, userdata);
        this.$store.commit('loading', true );
        this.$store.state.api.executeGet(this.$store,
            'procedurename=annexBCG&brgyID=' + this.state.current.brgyID +
            '&Budget_year=' +  this.state.current.budgetYear +
            '&code=' +  this.state.current.code,
            this.state.current);
    }
}

import { Vue, Component } from 'vue-property-decorator';
import Toolbar from '../Toolbar.vue';
import Report from '../Report.vue';
import SelectCategory from '../SelectCategory.vue';
import CrudBalance from '../CrudBalance.vue';
import CrudAT from '../CrudAT.vue';
import ProjectMenu from '../ProjectMenu.vue';
import Crud from '../Crud.vue';
import Preview from '../../components/Preview.vue';

@Component({
  components: {
    Toolbar,
    Report,
    SelectCategory,
    ProjectMenu,
    CrudBalance,
    CrudAT,
    Crud,
    Preview,
  },
})
export default class AnnexBCG extends Vue {
    public spUserVerification = this.$store.state.spUserVerification;
    public state = this.$store.state;
    public mounted() {
      this.$store.state.current.header = 'AnnexBCG';
      this.$store.state.current.status = this.$store.state.status.annexBCG;
    }

}


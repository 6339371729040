import jspdf from 'jspdf';
import {Cell} from './cell';

export class Row {
    public doc: any = {};
    public totalHeight = 216;
    public marginBottom = 16;
    public myCanvas: any = {};

    public style = {
        pLeft: 1,
        pRight: 1,
        pTop: 0,
        pBottom: 2,
        fontsize: 10,
        fontname: 'times',
        fontstyle: 'normal',
        widthstyle: 'fixed',
        heightstyle: 'auto',
        childChar: '   ',
        childSpacing: 1,
        width: 50,
        height: 50,
        align: 'left',
        valign: 'bottom',
    };

    public style2 = {
        pLeft: 1,
        pRight: 1,
        pTop: 0,
        pBottom: 2,
        fontsize: 10,
        fontname: 'tahoma',
        fontstyle: 'normal',
        widthstyle: 'fixed',
        heightstyle: 'fixed',
        childChar: '   ',
        childSpacing: 1,
        width : 50,
        height : 50,
        align : 'center',
        valign : 'middle',
    };

    public cord = {
        x : 0,
        y : 15,
        marginTop: 15,
        marginLeft: 15,
        marginRight: 15,
        marginBottom: 15,
        limit: 205,
        page: 1,
        totalWidth: 356,
        totalHeight: 216,
        drawCount: 0,
    };

    public constructor(obj: any) {
        this.doc = obj;
    }
    public render(cord: any, headers: any,  data: any, name: any) {


        const c = new Cell(this.doc, false, cord);

        const rHeader = JSON.parse(JSON.stringify(
            headers.filter((r: any) => r.value !== 'action'),
        ));

        const cd = JSON.parse(JSON.stringify(cord));
        let index = 0;
        data.map((d: any) => {
            index++;
            let fields: any = [];
            let t = '';
            rHeader.map((h: any) => {
                h.style.name = h.value;
                switch (h.value) {
                    case 'Title':
                        h.style.childChar = '    '.repeat(d.Lvl);
                        // console.log( d.lvl);
                        this.row(cd, fields, c, d.Title + d.Subtitle , h.style);
                        break;
                    case 'AIPCode':
                        this.row(cd, fields, c, d.AIPCode  , h.style);
                        break;
                    case 't2' :
                        this.row(cd, fields, c, d.t2  , h.style);
                        break;
                    case 't9' :
                        this.row(cd, fields, c, d.t9  , h.style);
                        break;
                    case 't10' :
                        t = (d.t10 === '') ? d.t5 : d.t10;
                        h.style.fontstyle = (d.t10 === '') ? 'bold' : 'normal';
                        this.row(cd, fields, c, t , h.style);
                        break;
                    case 't11' :
                        t = (d.t11 === '') ? d.t4 : d.t11;
                        h.style.fontstyle = (d.t11 === '') ? 'bold' : 'normal';
                        this.row(cd, fields, c, t  , h.style);
                        break;
                    case 't12' :
                        t = (d.t12 === '') ? d.t6 : d.t12;
                        h.style.fontstyle = (d.t12 === '') ? 'bold' : 'normal';
                        this.row(cd, fields, c, t  , h.style);
                        break;
                    case 't17' :
                        t = (d.t17 === '') ? d.t3 : d.t17;
                        h.style.fontstyle = (d.t17 === '') ? 'bold' : 'normal';
                        this.row(cd, fields, c, t  , h.style);
                        break;
                    case 't8' :
                        t = (d.t8 === '') ? d.t7 : d.t8;
                        h.style.fontstyle = (d.t8 === '') ? 'bold' : 'normal';
                        this.row(cd, fields, c, t  , h.style);
                        break;
                    case 'expected_results' :
                        this.row(cd, fields, c, d.expected_results  , h.style);
                        break;
                    case 'Performance_Indicator' :
                        this.row(cd, fields, c, d.Performance_Indicator  , h.style);
                        break;
                    default :
                        break;

                }

            });

            const th = Number(fields.sort(this.compareValues('totalHeight', 'desc'))[0].totalHeight);
            const excessContainer: any = [];
            let sumExcess = '';
            fields.map((f: any) => {
                const ret = c.virtualCellPrinter(f, th);
                sumExcess = sumExcess + ret.excessdata;
                excessContainer.push(ret);
            });

            cd.y = cd.y + th;
            cd.x = cord.x;


            if (cd.y > this.totalHeight - this.marginBottom) {
                this.doc.addPage({orientation: 'l', format: 'legal'});
                cd.y = cord.marginTop;
                cord.page++;
                if (index !== data.length) {
                    c.line(cd.x, cd.y, cd.totalWidth - cd.marginRight, cd.y);
                }
            }

            if (sumExcess.length > 0) {
                fields = [];
                rHeader.map((h: any) => {
                    const myvalue = excessContainer.filter((f: any) => f.name === h.value);
                    if (myvalue.length > 0 ) {
                        this.row(cd, fields, c, myvalue[0].excessdata , h.style);
                    }
                 });
                const nth = Number(fields.sort(this.compareValues('totalHeight', 'desc'))[0].totalHeight);
                // console.log ('fields', fields);
                fields.map((f: any) => {
                    c.virtualCellPrinter(f, nth);
                });

                cd.y = cd.y + nth;
                cd.x = cord.x;
            }
            // console.log(cd.y);
        });
        // console.log('last row y ' + cd.y);
        return cd.y;
    }

    public row(cd: any, fields: any , c: any, text: any, styl: any) {
        const o1 = c.virtualCell( cd, text, styl);
        cd.x = o1.lastx;
        fields.push(o1);
    }

    public compareValues(key: any, order = 'asc') {
        return function innerSort(a: any, b: any) {
          if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
            // property doesn't exist on either object
            return 0;
          }

          const varA = (typeof a[key] === 'string')
            ? a[key].toUpperCase() : a[key];
          const varB = (typeof b[key] === 'string')
            ? b[key].toUpperCase() : b[key];

          let comparison = 0;
          if (varA > varB) {
            comparison = 1;
          } else if (varA < varB) {
            comparison = -1;
          }
          return (
            (order === 'desc') ? (comparison * -1) : comparison
          );
        };
    }



    public ReportPdf_(headers: any, data: any, name: any) {
        this.doc = new jspdf({orientation: 'l', format: 'legal'});
        const c = new Cell(this.doc, false, this.cord);



        const styles = [];
        styles.push(JSON.parse(JSON.stringify(this.style)));
        styles.push(JSON.parse(JSON.stringify(this.style)));
        styles.push(JSON.parse(JSON.stringify(this.style)));
        styles.push(JSON.parse(JSON.stringify(this.style)));


        const cd = JSON.parse(JSON.stringify(this.cord));
        // console.log(cd);

        let fields: any = [];

        // const o1 = c.virtualCell( cd, 'ello', styles[0]);
        // cd.x = o1.lastx;
        // fields.push(o1);
        // // c.virtualCellPrinter(o1);

        // const o2 = c.virtualCell( cd, 'text data Another one bite the dust', styles[0]);
        // cd.x = o2.lastx;
        // fields.push(o2);
        // // c.virtualCellPrinter(o2);



        const mdata = [
            {
                id: 1,
                a: 'text data Another one bites the dust dum ',
                b: 'test data',
            },
            {
                id: 2,
                a: 'last part',
                b: 'test data',
            },
        ];

        const ncb = {
                id: 1,
                a: 'latest pushed',
                b: 'test data',
        };
        const ndata = mdata.sort(this.compareValues('id', 'desc'));

        // console.log(ndata.pop());
        // ndata.push(ncb);
        // console.log(ndata.pop());
        // console.log(ndata.pop());

        // console.log(mdata);





        cd.y = 190;
        const x = 1;
        do {
            const rec = ndata.pop();
            // this.row(cd, fields, c, (rec as any).id, styles[0]);
            fields = [];
            this.row(cd, fields, c, (rec as any).a, styles[0]);
            this.row(cd, fields, c, (rec as any).b, styles[0]);
            const th = Number(fields.sort(this.compareValues('totalHeight', 'desc'))[0].totalHeight);

            const excessContainer: any = [];
            const sumExcess = '';
            fields.map((d: any) => {
                 c.virtualCellPrinter(d, th);

            });


            // if (sumExcess.length > 0) {
            //     console.log('drawing/excess', excessContainer )
            //     const ncb = {
            //         id: 1,
            //         b: excessContainer.pop(),
            //         a: styles[0].childChar + excessContainer.pop(),

            //     }
            //     ndata.push(ncb);
            // }

            cd.y = cd.y + th;
            cd.x = this.cord.x;


            if (cd.y > this.totalHeight - this.marginBottom) {
                this.doc.addPage({orientation: 'l', format: 'legal'});
                cd.y = this.cord.y;
            }

        }
        while  (ndata.length > 0);

        // this.row(cd, fields, c, 'text data Another one bites the dust dum dum dum
        // Another one bites the dust dum dum dum Another one bites the dust dum dum dum
        // Another one bites the dust dum dum dum', styles[0]);
        // this.row(cd, fields, c, 'text ', styles[0]);
        // const th = Number(fields.sort(this.compareValues('totalHeight', 'desc'))[0].totalHeight);
        // fields.map((d:any)=>{ c.virtualCellPrinter(d, th); });

        // cd.y = cd.y + th;
        // cd.x = this.cord.x;

        // if (cd.y > this.totalHeight - this.marginBottom) {
        //     this.doc.addPage({orientation: 'l', format: 'legal'});
        //     cd.y = this.cord.y;
        // }

        // fields = [];
        // this.row(cd, fields, c, 'text data Another one bite the dust', styles[0]);
        // this.row(cd, fields, c, 'text ', styles[0]);
        // let h = Number(fields.sort(this.compareValues('totalHeight', 'desc'))[0].totalHeight);

        // fields.map((d:any)=>{ c.virtualCellPrinter(d, h); });

        // cd.y = cd.y + th;
        // cd.x = this.cord.x;


        // const o3 = c.virtualCell( cd, 'text data', styles[0]);
        // cd.x = o3.lastx;
        // fields.push(o3);
        // c.virtualCellPrinter(o3);

        // data.map((o: any) => {

        //     // row.load(o.title + o.subtitle, style[0])
        //     // row.load(o.accountcode)

        // })



        // obj2.style.height = 50;
        // obj2.style.heightstyle = 'fixed';



        this.doc.save( name + '.pdf');
        return 0;
    }

    public ReportCanvas(data: any, name: any) {
        // const c = new Cell(this.myCanvas, true);
        // const obj2 = c.virtualCell(
        //     this.cord, 'Calamity', this.style);
        // console.log('drawing', obj2);
        // c.virtualCellPrinter(obj2);
        // this.myCanvas.moveTo(0,0);
        // this.myCanvas.lineTo(200,150);
        // this.myCanvas.stroke();
        // c.setFont('Ariel', 'normal');
        // c.text('Hello World', this.cord.y * 2, this.cord.x * 2);
        return 0;
    }

}

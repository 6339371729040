import { Vue, Component } from 'vue-property-decorator';
import DataCom from '../components/DataCom.vue';
import Report from '../components/Report.vue';
import Status from '../components/Status.vue';
@Component({
    components: {
        DataCom,
        Report,
        Status,
    },
})
export default class AnnexD extends Vue {
    public spUserVerification = this.$store.state.spUserVerification;
    public state = this.$store.state;
    public mounted() {
        this.state.reportDetails = {
            name: 'Annex D',
            formNumber: 'Baranagy Budget Preparation Form No. 2A',
            title: 'LIST OF PROJECTS CHARGEABLE AGAINST THE 20% DEVELOPMENT FUND, FY ' + this.state.current.budgetYear,
            subtitle: '',
            cy: 'CY-' + this.state.current.budgetYear,
            barangay: 'Barangay ' +
                this.state.current.brgy_name.toLowerCase().replace(/^\w/, (c: any) => c.toUpperCase()),
            city: 'City of Davao',
            classification: 'GENERAL FUND',
            capitan: this.state.current.captain,
            secretary: this.state.current.secretary,
            treasurer: this.state.current.treasurer,
          };

        this.$store.state.current.header = 'AnnexD';
        this.$store.state.current.status = this.$store.state.status.annexD;
        this.$store.commit('loading', true );
        this.$store.state.api.executeGet(this.$store,
            'procedurename=spGet_AnnexDReport&brgy_id=' + this.state.current.brgyID +
            '&year=' +  this.state.current.budgetYear);

    }

    public clear() {
        // this.$refs.dataCom.clear();
        (this.$refs.dataCom as Vue & { clear: () => boolean }).clear();
    }
    public deleteRecord(headers: any) {
        const field = (fieldname: string) => headers.filter((a: any) => a.value === fieldname)[0].model;
        const param = {
            parameters:
            {
                key: this.$store.state.apiKey,
                procedurename: 'spdeleteAnnexDdetails',
                devfunddetailsID: field('devfunddetailsID'),
            },
        };

        this.$store.commit('loading', true );
        this.$store.state.api.executePost(this.$store, param, this);
    }
    public save(headers: any) {
        const field = (fieldname: string) => headers.filter((a: any) => a.value === fieldname)[0].model;
        const param = {
            parameters:
            {
                key: this.$store.state.apiKey,
                procedurename: 'spAnnexDUpdate',
                brgy_id: this.state.current.brgyID,
                year: this.state.current.budgetYear,
                AIP_code: field('AIP_code'),
                details_proj: field('details_proj'),
                amount: field('amount'),
                imp_sched: field('imp_sched'),
                expected_output: field('expected_output'),
                devfunddetailsID: field('devfunddetailsID'),
            },
        };

        this.$store.commit('loading', true );
        this.$store.state.api.executePost(this.$store, param, this);
    }
}


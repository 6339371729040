import { Vue, Component } from 'vue-property-decorator';


@Component
export default class UVApproval extends Vue {
    public headers: any = [
        {
          text: 'Name',
          align: 'left',
          sortable: false,
          value: 'name',
        },
        {
            text: 'Barangay',
            align: 'left',
            sortable: false,
            value: 'brgy_name',
        },
        {
          text: 'Actions',
          value: 'action',
          sortable: false,
          align: 'center',

         },
      ];

    public objHandler = {
        headers: [
            {
                text: 'Name',
                align: 'left',
                sortable: false,
                value: 'name',
            },
            {
                text: 'Barangay',
                align: 'left',
                sortable: false,
                value: 'brgy_name',
            },
            {
                text: 'Actions',
                value: 'action',
                sortable: false,
            },
        ],
        itemList: [],
    };
    public spUserVerification = this.$store.state.spUserVerification;
    public mounted() {
        // console.log('spGetApprovalRequest');
        this.$store.state.api.executeGet(this.$store, 'procedurename=spGetApprovalRequest', this.objHandler, true);
    }
    public approved(item: any) {
        const param = {
            parameters:
            {
                key: this.$store.state.apiKey,
                procedurename: 'spSetApprovalRequest',
                userid: item.userid,
            },
          };

        this.$store.state.api.executePost(this.$store, param, this.objHandler);
    }
    public rejected(item: any) {
        const param = {
        parameters:
        {
            key: this.$store.state.apiKey,
            procedurename: 'spSetRejectedRequest',
            userid:  item.userid,
        },
        };
        this.$store.state.api.executePost(this.$store, param, this.objHandler);
    }

}


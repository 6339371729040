import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import acntTitle from '../AccountTitle.vue';


@Component({
  components: {
    acntTitle,
  },
})
export default class AnnexBCG extends Vue {
    public spUserVerification = this.$store.state.spUserVerification;
    public state = this.$store.state;
    public saveValid = true;
    @Prop(Boolean) public editMode: false | undefined;

    public save() {
      this.check();
      if (this.saveValid) {
          const param = {
              parameters:
              {
                  key: this.$store.state.apiKey,
                  procedurename: 'spAnnexBCGUpdate',
                  brgy_id: this.state.current.brgyID,
                  budget_year: this.state.current.budgetYear,
                  FPP_id : this.state.current.fppid,
                  account_id : this.state.crudAT.account_id ,
                  Pastyear_Actual : this.state.crudAT.pastyearActual,
                  FirstSem_Actual : this.state.crudAT.firstSemActual,
                  SecondSem_Esimate : this.state.crudAT.secondSemEsimate,
                  Budgetyear_proposed : this.state.crudAT.BudgetyearProposed ,
                  IncomeClass : this.state.crudAT.Curring,
                  performance_indicator : this.state.crudAT.performance_indicator,
                  expected_result : this.state.crudAT.expected_results,
              },
          };
          this.$store.commit('loading', true );
          this.$store.state.api.executePost(this.$store, param, this.state.current);
      }
    }

    public cancel() {
      this.state.crudAT.account_id = 0;
      this.state.crudAT.Curring = '';
      this.state.crudAT.pastyearActual = '';
      this.state.crudAT.firstSemActual = '';
      this.state.crudAT.secondSemEsimate = '';
      this.state.crudAT.BudgetyearProposed = '';
      this.state.crudAT.expected_results = '';
      this.state.crudAT.performance_indicator = '';
      this.state.crudAT.search = '';
      this.state.dialog.save = false;
      this.saveValid = true;
    }

    @Watch('state.crudAT.account_id')
    public crudAT_Changed(val: any, old: any) {
      this.check();
    }
    @Watch('state.crudAT.pastyearActual')
    public pastyearActual_Changed(val: any, old: any) {
      this.check();
    }
    @Watch('state.crudAT.firstSemActual')
    public firstSemActual_Changed(val: any, old: any) {
      this.check();
    }
    @Watch('state.crudAT.secondSemEsimate')
    public secondSemEsimate_Changed(val: any, old: any) {
      this.check();
    }
    @Watch('state.crudAT.BudgetyearProposed')
    public BudgetyearProposed_Changed(val: any, old: any) {
      this.check();
    }

    public check() {
      let valid = false;
      let values = '';
      const c = this.state.crudAT;
      if (this.state.crudAT.account_id > 0) {
        values =  c.pastyearActual + c.firstSemActual +
                  c.secondSemEsimate + c.BudgetyearProposed +
                  c.expected_results + c.performance_indicator;
        if (values.trim() !== '' ) { valid = true; }

      }
      this.saveValid = valid;
      // console.log('crudAT/values', values);
      // console.log('crudAT/valid', valid);
    }

}




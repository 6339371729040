import { Vue, Component, Watch, Prop } from 'vue-property-decorator';

@Component
export default class AT extends Vue {
    @Prop(String) public search: string | undefined;
    @Prop(Number) public activeIndex: 0 | undefined;
    public spUserVerification = this.$store.state.spUserVerification;
    public get mActiveIndex() {
        return (this.activeIndex === undefined) ? 0 : this.activeIndex;
    }
    public state = this.$store.state;
    public position = 0;

    public headers = [
        {name: 'account_name', text: 'Account Title', visible: true, width: 500},
        {name: 'pastyearActual', text: 'Pastyear Actual', visible: true, width: 100},
        {name: 'firstSemActual', text: 'first Sem Actual', visible: true, width: 100},
        {name: 'secondSemEsimate', text: 'second Sem Esimate', visible: true, width: 100},
        {name: 'BudgetyearProposed', text: 'Budgetyear Proposed', visible: true, width: 100},
    ];

    public onscroll() {
        return '';
    }

    public dataClicked(obj: any) {
        this.$emit('dataClicked', obj);
    }

    @Watch ('state.crudAT.search')
    public onspGetAccountTitles( val: any, oldVal: any) {
        // alert('at/val hit');
        // console.log('at/val', val);

        if (val === '') {
            this.state.AccountTitles = this.state.spGetAccountTitles;
        } else {
            const data = this.state.spGetAccountTitles.filter((a: any) =>
            a.account_name.toLowerCase().includes(val.toLowerCase()));
            this.state.AccountTitles = data;

            // console.log('data', data.length);
            // console.log('crudAT', this.state.crudAT);

            if (data.length > 0) {


                this.state.crudAT.account_id = data[0].account_id;

                // console.log('this.state.crudAT.account_id ',this.state.crudAT.account_id );
                // console.log('data[0].account_id ', data[0].account_id);

                this.state.crudAT.accountTitle = data[0].accountTitle;
                this.state.crudAT.accountCode = data[0].accountCode;
                this.state.crudAT.pastyearActual = data[0].pastyearActual;
                this.state.crudAT.firstSemActual = data[0].firstSemActual;
                this.state.crudAT.secondSemEsimate = data[0].secondSemEsimate;
                this.state.crudAT.BudgetyearProposed = data[0].BudgetyearProposed;


                const bcgdata = this.state.annexBCG.filter((d: any) =>
                        d.ID === data[0].account_id && d.Grpname === 'acnt')[0];
                if (bcgdata !== undefined) {
                    if (bcgdata.length > 0) {
                        this.state.crudAT.expected_results = bcgdata.expected_results;
                        this.state.crudAT.performance_indicator = bcgdata.Performance_Indicator;
                    }
                }

                // console.log('at/annexBCG', this.state.annexBCG);
                // console.log('at/bcgdata4', bcgdata);
                // console.log('at/data[0]', data[0] );
                // this.state.crudAT.Curring = bcgdata.t9;
                /*
                this.state.crudAT.expected_results = bcgdata.expected_results;
                this.state.crudAT.performance_indicator = bcgdata.Performance_Indicator;
                */

                // console.log('at/crudAT', this.state.crudAT);
                // console.log('at/data', data );
                // console.log('at/state.AccountTitle', this.state.AccountTitles );

            }

        }

    }

    @Watch ('activeIndex')
    public onactiveIndex( val: any, oldVal: any) {
        const index = (this.activeIndex === undefined)  ? 0 : this.activeIndex;

        let obj: HTMLElement  = document.createElement('div');

        const elmnt = document.getElementById('boxid');
        obj = (elmnt === null) ? obj : elmnt;

        const height = (elmnt === null) ? 0 : elmnt.clientHeight;
        obj.scrollTop = obj.scrollHeight;
        const max = obj.scrollTop;
        obj.scrollTop = 0;
        const unit = max / this.state.AccountTitles.length;
        const yTop = (index + 2) * unit;
        if (yTop > height) {
            obj.scrollTop = yTop;
        }

        // console.log('activeIndex', index);
        // console.log('height',  height);
        // console.log('elmnt', max);
        // console.log('AccountTitles', this.state.AccountTitles.length);


    }
}


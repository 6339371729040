import jspdf from 'jspdf';
import {Cell} from './cell';
import {Row} from './row';
export class Drawing {
    public doc: any = {};
    public totalHeight = 216;
    public marginBottom = 16;
    public myCanvas: any = {};

    public style = {
        pLeft: 1,
        pRight: 1,
        pTop: 1,
        pBottom: 2,
        fontsize: 10,
        fontname: 'times',
        fontstyle: 'normal',
        widthstyle: 'fixed',
        heightstyle: 'auto',
        childChar: '   ',
        childSpacing: 1,
        width: 356,
        height: 50,
        align: 'center',
        valign: 'bottom',
        border: '1,1,1,1',
    };


    public cord = {
        x : 0,
        y : 15,
        marginTop: 22,
        marginLeft: 15,
        marginRight: 15,
        marginBottom: 15,
        limit: 205,
        page: 1,
        totalWidth: 356,
        totalHeight: 216,
        drawCount: 0,
    };

    public ReportPdf(headers: any,  data: any, details: any) {
        this.doc = new jspdf({orientation: 'l', format: 'legal'});
        const c = new Cell(this.doc, false, this.cord);
        const r = new Row(this.doc); // r.render(headers,data,details);

        this.cord.x = this.cord.marginLeft;
        this.style.width = this.cord.totalWidth - this.cord.marginLeft - this.cord.marginRight;
        const cd = JSON.parse(JSON.stringify(this.cord));
        let page1y = 0;

        const styl = JSON.parse(JSON.stringify(this.style));
        cd.y = 15;
        cd.x = cd.marginLeft;
        styl.width = cd.totalWidth  - cd.marginLeft - cd.marginRight;
        styl.border = '0,0,0,0';
        styl.align = 'right';
        styl.pRight = 0;
        styl.fontstyle = 'bold';
        let obj = c.draw(cd, details.name, styl);

        cd.y = cd.y + obj.totalHeight;
        styl.fontstyle = 'normal';
        styl.align = 'left';
        styl.heightstyle = 'fixed';
        styl.height = 10;
        styl.valign = 'top';
        obj = c.draw(cd, details.formNumber, styl);

        cd.y = cd.y + obj.totalHeight;
        styl.fontstyle = 'bold';
        styl.align = 'center';
        styl.heightstyle = 'auto';
        styl.height = 2;
        styl.valign = 'top';
        obj = c.draw(cd, details.title, styl);

        cd.y = cd.y + obj.totalHeight;
        styl.fontstyle = 'bold';
        styl.align = 'center';
        styl.heightstyle = 'auto';
        styl.height = 2;
        styl.valign = 'top';
        obj = c.draw(cd, details.barangay, styl);

        cd.y = cd.y + obj.totalHeight;
        styl.heightstyle = 'fixed';
        styl.height = 10;
        obj = c.draw(cd, details.city, styl);

        cd.y = cd.y + obj.totalHeight;
        styl.fontstyle = 'bold';
        styl.align = 'center';
        styl.heightstyle = 'fixed';
        styl.height = 5;
        styl.valign = 'bottom';
        obj = c.draw(cd, details.classification, styl);

        cd.y = cd.y + obj.totalHeight;
        styl.fontstyle = 'normal';
        styl.align = 'right';
        styl.heightstyle = 'auto';
        styl.height = 10;
        styl.value = 'middle';
        page1y = cd.y - 2;
        cd.y = cd.y + obj.totalHeight;



        const rheaderOrig = JSON.parse(JSON.stringify(
            headers.filter((rh: any) => rh.value !== 'action'),
        ));
        const rheader = JSON.parse(JSON.stringify(
            headers.filter((rh: any) => rh.value !== 'action'),
        ));
        const h1 = rheader.filter((h: any) => 'Title-AIPCode-t2-t9-'.includes(h.value));
        h1.map((h: any) => {
            let o: any = {};
            h.style.height = 25;
            h.style.heightstyle = 'fixed';
            h.style.valign = 'middle';
            h.style.align = 'center';
            h.style.border = '1,1,0,0';
            if (h.value === 'Title') {
                h.style.border = '1,1,0,1';
            }
            o = c.draw(cd, h.text, h.style);
            cd.x = o.lastx;
        });

        const h2 = rheader.filter((h: any) => !'Title-AIPCode-t2-t9-'.includes(h.value));
        const rm = h2.length;
        const wd = (cd.totalWidth - cd.x -  cd.marginRight) / rm;

        let xoft11 = 0;
        let z = 0;
        let onappro = false;
        h2.map((h: any) => {
            z++;
            let o: any = {};
            h.style.height = 25;
            h.style.width = wd;
            h.style.heightstyle = 'fixed';
            h.style.valign = 'middle';
            h.style.align = 'center';
            h.style.border = '1,1,0,0';
            if (h.value  === 't11') {
                xoft11 = cd.x;
                onappro  = true;
            }
            o = c.draw(cd, h.text, h.style);
            cd.x = o.lastx;
        });

        if (onappro) {
            const xofstyle = JSON.parse(JSON.stringify(this.style));
            xofstyle.width = wd * 3;
            cd.x = xoft11;
            xofstyle.border = '1,1,1,1';
            xofstyle.FillColor = '255,255,255';
            c.draw(cd, details.appropriation , xofstyle);
        }

        cd.y = cd.y + 25;
        cd.x = cd.marginLeft;
        let ctr = 0;
        let lastHeight = 0;
        rheader.map((h: any) => {
            let o: any = {};
            ctr++;
            h.style.heightstyle = 'auto';
            h.style.border = '0,1,1,0';
            if (ctr === 1) {
                h.style.border = '0,1,1,1';
            }
            o = c.draw(cd, '(' + ctr + ')', h.style);
            lastHeight = o.totalHeight;
            cd.x = o.lastx;
        });

        cd.y = cd.y + lastHeight;
        cd.x = cd.marginLeft;
        for (let i = 0 ; i < rheader.length; i++) {
            rheaderOrig[i].style.width = rheader[i].style.width;
            rheaderOrig[i].style.border = rheader[i].style.border;
        }

        cd.y = r.render(cd, rheaderOrig , data , details);

        this.drawPreparedby(c, cd, details, this.style);

        const s = JSON.parse(JSON.stringify(this.style));
        cd.y = 15;
        cd.x = cd.marginLeft;
        s.width = cd.totalWidth  - cd.marginLeft - cd.marginRight;
        s.border = '0,0,0,0';
        s.align = 'right';
        s.pRight = 0;
        // s.FillColor = '255,255,255'
        // s.style.heightstyle = 'fixed';
        this.doc.setPage(1);
        cd.y = page1y;

        c.draw(cd, 'page 1 of ' + cd.page + ' pages', s);
        for (let j = 2 ; j <= cd.page; j++) {
            cd.y = 15;
            this.doc.setPage(j);

            c.draw(cd, 'page ' + j + ' of ' + cd.page + ' pages', s);
        }

        this.doc.save( details.name + '.pdf');

    }

    public drawPreparedby(cell: any, cd: any, details: any, style: any) {
        const styl = JSON.parse(JSON.stringify(style));

        // cd.y = cd.y + 100;
        cd.y = cd.y + 25;
        cd.x = cd.marginLeft;
        styl.width = (cd.totalWidth  - cd.marginLeft - cd.marginRight) * .7;
        styl.border = '0,0,0,0';
        styl.align = 'left';
        styl.pRight = 0;
        styl.fontstyle = 'bold';
        let obj = cell.draw(cd, 'PREPARED BY:', styl);

        cd.x = obj.lastx;
        obj = cell.draw(cd, 'APPROVED BY:', styl);
        cd.y = cd.y + (obj.totalHeight * 2);
        const lasty = cd.y;

        cd.y = lasty;
        styl.width = 50;
        styl.align = 'center';
        cd.x = cd.marginLeft;
        styl.fontstyle = 'bold';
        obj = cell.draw(cd, details.treasurer.toUpperCase(), styl);
        cd.y = cd.y + obj.totalHeight ;
        styl.fontstyle = 'normal';
        obj = cell.draw(cd, 'Barangay Treasurer', styl);

        cd.y = lasty;
        styl.width = 50;
        styl.align = 'center';
        cd.x = cd.marginLeft + 100;
        styl.fontstyle = 'bold';
        obj = cell.draw(cd, details.secretary.toUpperCase(), styl);
        cd.y = cd.y + obj.totalHeight ;
        styl.fontstyle = 'normal';
        obj = cell.draw(cd, 'Barangay Secretary', styl);

        cd.y = lasty;
        styl.width = 50;
        styl.align = 'center';
        cd.x = ((cd.totalWidth  - cd.marginLeft - cd.marginRight) * .7) + 50;
        styl.fontstyle = 'bold';
        obj = cell.draw(cd, details.capitan.toUpperCase(), styl);
        cd.y = cd.y + obj.totalHeight ;
        styl.fontstyle = 'normal';
        obj = cell.draw(cd, 'Punong Barangay', styl);

    }

    public ReportCanvas(data: any, name: any) {
        // const c = new Cell(this.myCanvas, true);
        // const obj2 = c.virtualCell(
        //     this.cord, 'Calamity', this.style);
        // console.log('drawing', obj2);
        // c.virtualCellPrinter(obj2);
        // this.myCanvas.moveTo(0,0);
        // this.myCanvas.lineTo(200,150);
        // this.myCanvas.stroke();
        // c.setFont('Ariel', 'normal');
        // c.text('Hello World', this.cord.y * 2, this.cord.x * 2);
        return 0;
    }

}

import { render, staticRenderFns } from "./VForApproval.vue?vue&type=template&id=9b260858&scoped=true&"
import script from "./v-for-approval.ts?vue&type=script&scoped=true&lang=js&"
export * from "./v-for-approval.ts?vue&type=script&scoped=true&lang=js&"
import style0 from "./v-for-approval.scss?vue&type=style&index=0&id=9b260858&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9b260858",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VContent } from 'vuetify/lib/components/VContent';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VContent,VFlex,VLayout})

import Vue from 'vue';
import Amplify from 'aws-amplify';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import config from './config.json';

// import readXlsxFile from 'read-excel-file'

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
});

Vue.config.productionTip = false;
router.push('/Home');
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');

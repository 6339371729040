import Vue from 'vue';
import VueRouter from 'vue-router';
// import Home from '../views/Home.vue';
import Login from '../components/login/Login.vue';
import about from '../views/About.vue';
import Welcome from '../views/welcome/Welcome.vue';
import ForgotPassword from '../views/forgot-password/ForgotPassword.vue';
import Verification from '../views/forgot-password-verification/Verification.vue';
import UserVerification from '../views/user-verification/UserVerification.vue';
import UserVerificationForApproval from '../views/user-verification-for-approval/VForApproval.vue';
import UVApproval from '../views/user-verification-approval/UVApproval.vue';

import AnnexA from '../views/forms/annex-a/AnnexA.vue';
import AnnexBCG from '../views/forms/annex-bcg/AnnexBCG.vue';
import AnnexD from '../views/forms/annex-d/AnnexD.vue';
import AnnexE from '../views/forms/annex-e/AnnexE.vue';
import AnnexF from '../views/forms/annex-f/AnnexF.vue';
import RptAnnexA from '../views/reports/rpt-annex-a/RptAnnexA.vue';
import RptAnnexB from '../views/reports/rpt-annex-b/RptAnnexB.vue';
import RptAnnexC from '../views/reports/rpt-annex-c/RptAnnexC.vue';
import RptAnnexD from '../views/reports/rpt-annex-d/RptAnnexD.vue';
import RptAnnexE from '../views/reports/rpt-annex-e/RptAnnexE.vue';
import RptAnnexF from '../views/reports/rpt-annex-f/RptAnnexF.vue';
import RptAnnexG from '../views/reports/rpt-annex-g/RptAnnexG.vue';
import Lab1 from '../_labratory/lab1/Lab1.vue';
import Lab2 from '../_labratory/lab2/lab2.vue';
import Lab3 from '../_labratory/lab3/Nemtreetest.vue';
import BarangayList from '../views/forms/barangayList/BarangayList.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Login,
  },
  {
    path: '/home',
    component: Login,
  },
  {
    path: '/Welcome',
    component: Welcome,
  },
  {
    path: '/forgotpassword',
    component: ForgotPassword,
  },
  {
    path: '/verification',
    component: Verification,
  },
  {
    path: '/about',
    component: about,
  },
  {
    path: '/userverification',
    component: UserVerification,
  },
  {
    path: '/userverificationforapproval',
    component: UserVerificationForApproval,
  },
  {
    path: '/userverificationapproval',
    component: UVApproval,
  },
  {
    path: '/annexa',
    component: AnnexA,
  },
  {
    path: '/annexbcg',
    component: AnnexBCG,
  },
  {
    path: '/annexd',
    name: 'annexd',
    component: AnnexD,
  },
  {
    path: '/annexe',
    component: AnnexE,
  },
  {
    path: '/annexf',
    component: AnnexF,
  },
  {
    path: '/rptannexa',
    component: RptAnnexA,
  },
  {
    path: '/rptannexb',
    component: RptAnnexB,
  },
  {
    path: '/rptannexc',
    component: RptAnnexC,
  },
  {
    path: '/rptannexd',
    component: RptAnnexD,
  },
  {
    path: '/rptannexe',
    component: RptAnnexE,
  },
  {
    path: '/rptannexf',
    component: RptAnnexF,
  },
  {
    path: '/rptannexg',
    name: 'rptannexg',
    component: RptAnnexG,
  },
  {
    path: '/lab1',
    component: Lab1,
  },
  {
    path: '/lab2',
    component: Lab2,
  },
  {
    path: '/Lab3',
    component: Lab3,
  },
  {
    path: '/BarangayList/:year',
    component: BarangayList,
  },
];

const router = new VueRouter({
  routes,
});

export default router;

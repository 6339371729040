import jspdf from 'jspdf';


export class Cell {
    public doc: any = {};
    public canvas: any = {};
    public onCanvas = false;
    public coordinates: any = {};

    public constructor(obj: any, monCanvas: boolean, cord: any) {
        this.onCanvas = monCanvas;
        this.coordinates = cord;

        if (monCanvas ) {
            this.canvas = obj;
        } else {
            this.doc = obj;
        }

    }

    public virtualCellPrinter(obj: any, th: number) {
        if (th > 0) {
            obj.style.height = th;
            obj.style.heightstyle = 'fixed';
        }

        const o = this.doc;
        const s = obj.style;
        let excess = '';
        // o.setFont(s.fontname, s.fontstyle);
        // o.setFontSize(s.fontsize);
        this.font(s.fontname, s.fontstyle, s.fontsize);

        let ht = obj.baseHeight;

        const i =  obj.text.length;
        const txtHeight = ((obj.txtHeight + s.childSpacing) * (i - 1)) + obj.txtHeight;

        if (s.heightstyle === 'fixed' ) {
            ht = this.yAxisAlign(s, ht - s.pTop, txtHeight, s.height);
        }

        obj.rect.map((r: any) => {
            if (s.heightstyle === 'fixed' ) {
                this.rectangle(r.x, r.y,  r.w, s.height , obj.style);
            } else {
                this.rectangle(r.x, r.y,  r.w, r.h, obj.style );
            }

        });

        obj.text.map((t: any) => {
            let success = false;
            if (s.heightstyle === 'fixed' ) {
                // console.log('v', ht + ',' + obj.txtHeight + ',' +  s.childSpacing);
                success = this.text(t.text, t.x , ht);
                ht = ht + obj.txtHeight + s.childSpacing;
            } else {
                success = this.text(t.text, t.x , t.y);
            }

            if (!success) {
                excess = (excess + ' ' + t.text.trim()).trim();
            }

        });



        if (th > 0) {
            obj.style.height = th;
            obj.style.heightstyle = 'auto';
        }
        return {
            excessdata: excess,
            name: obj.style.name,
        };
    }

    public virtualCell(c: any, txt: any, s: any) {
        const o = this.doc;
        const drawText = [];
        const drawRect = [];

        let mtotalHeight = 0;

        // this.setFont(s.fontname, s.fontstyle);
        // this.setFontSize(s.fontsize);
        this.font(s.fontname, s.fontstyle, s.fontsize);

        let d = this.getTextDimensions(txt);
        const dh = d.h;
        const baseht = c.y + d.h + s.pTop;

        if (s.widthstyle === 'auto') {
            drawRect.push ({x: c.x, y: c.y,
                w: d.w + s.pLeft + s.pRight,
                h: d.h + s.pBottom + s.pTop});

            drawText.push ({text: txt, x: c.x + s.pLeft , y: c.y + d.h + s.pTop});
            mtotalHeight =  d.h + s.pBottom;


        }
        if (s.widthstyle === 'fixed') {
            const allowableWidth = s.width - s.pLeft - s.pRight;
            // console.log('allowableWidth', allowableWidth);
            // console.log('d.w', d.w);
            if (d.w > allowableWidth) {

                const txtdata = this.setTextArray(txt, allowableWidth , s.childChar, ' ', o);

                const i =  txtdata.length;
                const txtHeight = ((d.h + s.childSpacing) * (i - 1)) + d.h;
                let ht = c.y + d.h + s.pTop;
                let recHeight = txtHeight + s.pBottom + s.pTop;
                if (s.heightstyle === 'fixed') {
                    ht = this.yAxisAlign(s, ht - s.pTop, txtHeight, s.height );
                    recHeight = s.height;
                }



                txtdata.map((m: any) => {
                    d = this.getTextDimensions(m);
                    const xaxisn =  this.xAxisAlign(s, c, d.w, allowableWidth);
                    drawText.push ({text: m, x: xaxisn , y: ht});
                    ht = ht + d.h + s.childSpacing;
                });

                drawRect.push({x: c.x, y: c.y,
                    w: s.width,
                    h: recHeight});

                mtotalHeight =  recHeight;

            } else {
                const txtHeight = d.h;
                const recHeight = txtHeight + s.pBottom + s.pTop;
                const ht = c.y + d.h + s.pTop;
                // if (s.heightstyle === 'fixed') {
                //     ht = this.yAxisAlign(s, ht - s.pTop, txtHeight, s.height );
                //     recHeight = s.height;
                // }

                drawRect.push ({x: c.x, y: c.y,
                    w: s.width,
                    h: recHeight});

                const xaxis =  this.xAxisAlign(s, c, d.w, s.width);
                drawText.push({text: txt, x: xaxis , y: ht});
                mtotalHeight =  recHeight;
            }

            if (s.heightstyle === 'fixed') {
                mtotalHeight = s.height;
            }

        }

        return {
            style: s,
            coord: c,
            baseHeight: baseht,
            totalHeight: mtotalHeight,
            lastx: c.x + s.width,
            text: drawText,
            rect: drawRect,
            txtHeight: dh,
        };

    }

    public draw(c: any, txt: any, s: any) {
        const o = this.virtualCell( c, txt, s);
        this.virtualCellPrinter(o, 0);
        c.drawCount++;
        return o;
    }

    // this.celllongated(this.cord, "Calamity fund(5%ofEstimatedIncomefromRegular Source BY 2020)
    // Davao City Philippines", this.style);
    public cell(c: any, txt: string, s: any) {
        const o = this.doc;
        // this.setFont(s.fontname, s.fontstyle);
        // this.setFontSize(s.fontsize);
        this.font(s.fontname, s.fontstyle, s.fontsize);
        let d = this.getTextDimensions(txt);

        if (s.widthstyle === 'auto') {
            this.rectangle(c.x, c.y,
                d.w + s.pLeft + s.pRight,
                d.h + s.pBottom + s.pTop , s);
            this.text(txt, c.x + s.pLeft , c.y + d.h + s.pTop);
            return {
                style: s,
                totalHeight: d.h + s.pBottom,
            };
        }
        if (s.widthstyle === 'fixed') {
            const allowableWidth = s.width - s.pLeft - s.pRight;
            // console.log('allowableWidth', allowableWidth);
            // console.log('d.w', d.w);
            if (d.w > allowableWidth) {

                const txtdata = this.setTextArray(txt, allowableWidth , s.childChar, ' ', o);

                const i =  txtdata.length;
                const txtHeight = ((d.h + s.childSpacing) * (i - 1)) + d.h;

                let ht = c.y + d.h + s.pTop;
                let recHeight = txtHeight + s.pBottom + s.pTop;

                if (s.heightstyle === 'fixed') {
                    ht = this.yAxisAlign(s, ht - s.pTop, txtHeight, s.height );
                    recHeight = s.height;
                }



                txtdata.map((m: any) => {
                    d = this.getTextDimensions(m);
                    const xaxisn =  this.xAxisAlign(s, c, d.w, allowableWidth);
                    // console.log('o', ht + ',' +  d.h + ',' +  s.childSpacing);
                    this.text(m, xaxisn , ht);
                    ht = ht + d.h + s.childSpacing;
                });

                this.rectangle(c.x, c.y,
                    s.width,
                    recHeight, s);

                return {
                    style: s,
                    totalHeight: recHeight,
                };

            } else {
                const txtHeight = d.h;
                let recHeight = txtHeight + s.pBottom + s.pTop;
                let ht = c.y + d.h + s.pTop;
                if (s.heightstyle === 'fixed') {
                    ht = this.yAxisAlign(s, ht - s.pTop, txtHeight, s.height );
                    recHeight = s.height;
                }

                this.rectangle(c.x, c.y,
                    s.width,
                    recHeight, s);

                const xaxis =  this.xAxisAlign(s, c, d.w, s.width);
                this.text(txt, xaxis , ht);
                return {
                    style: s,
                    totalHeight: recHeight,
                };
            }


        }

    }

    public yAxisAlign(s: any, cy: any, txtHeight: any, rectHeight: any) {
        let yaxisAlign =  cy + s.pLeft;
        if (s.valign === 'middle') {
            const r = rectHeight / 2;
            const t = txtHeight / 2;
            yaxisAlign = cy + r - t;
        }
        if (s.valign === 'bottom') {
            yaxisAlign = cy + rectHeight  - txtHeight - s.pBottom;
        }
        return yaxisAlign;
    }

    public xAxisAlign(s: any, c: any, txtWidth: any, allowableWidth: any) {
        let xaxisAlign =  c.x + s.pLeft;
        if (s.align === 'center') {
            const r = allowableWidth / 2;
            const t = txtWidth / 2;
            xaxisAlign = c.x + r - t;
        }
        if (s.align === 'right') {
            xaxisAlign = c.x + allowableWidth  - txtWidth - s.pRight;
        }
        return xaxisAlign;
    }

    public setTextArray(txt: any, allowableWidth: any, childChar: any, splitter: any , o: any ) {
        const txtArr = txt.split(splitter);
        const txtdata = [];
        let mytxt = '';
        let spacer = '';
        let ctr = 1;
        txtArr.map((t: any) => {
            const p = mytxt;
            mytxt = mytxt + spacer + t;
            const c = this.getTextDimensions(mytxt);

            if (c.w > allowableWidth) {
                if (ctr === 1 ) {
                    const dd = this.setTextArray(p, allowableWidth , childChar, '', o);
                    dd.map((dm: any) => {
                        txtdata.push(dm);
                    });
                } else {
                    txtdata.push(p);
                }

                mytxt = childChar + t;

                ctr = 0;
            }
            ctr++;
            spacer = splitter;
        });

        txtdata.push(mytxt);

        return txtdata.filter((mm: any) => mm !== '');
    }
    // this.rectangle(0,0, 100, 5);
    public rectangle(x: number, y: number, w: number, h: number, style: any) {
        if ((y + h ) > this.coordinates.limit ) {
            h = this.coordinates.limit - y;

        }
        if (this.onCanvas) {
            const ctx = this.canvas;
            ctx.beginPath();
            ctx.rect(x, y,  w, h);
            ctx.stroke();
        } else {
            const o = this.doc;
            if (style.hasOwnProperty('FillColor')) {
                const f = style.FillColor + ',0,0,0,0';
                // o.setFillColor(255, 255, 255);
                o.setFillColor(f[0], f[1], f[2]);
                o.rect(x, y,  w, h, 'F');
            }

        // rect
            this.square(x, y,  w, h, style);         // rect

        }

    }
    public line(x: any, y: any, x2: any, y2: any) {
        const o = this.doc;
        o.line(x , y, x2, y2);
    }
    public square(x: any, y: any, w: any, h: any, style: any) {
        const o = this.doc;
        if (!style.hasOwnProperty('border')) {
            style.border = '1,1,1,1';
        }
        const border = style.border + ',0,0,0,0';
        const b = border.split(',');


        if (b[0] === '1') {o.line(x, y, x + w, y); }         // horizontal line top

        if (b[1] === '1') {
            o.line(x + w, y, x + w, y + h);
        } // vertical line right

        if (b[2] === '1') {o.line(x, y + h, x + w, y + h); } // horizontal line bottom

        if (b[3] === '1') {
            o.line(x, y, x, y + h);
        }  // vertical line left
        // console.log(b[3])
        // o.line(x, y, x, y + h);

    }

    public text(text: string, x: number, y: number) {
        if (y <= this.coordinates.limit) {
            if (this.onCanvas) {
                const c = this.canvas;
                c.fillText(text, x , y);
            } else {
                const o = this.doc;
                o.text(text, x , y);
            }
            return true;
        } else {
            return false;
        }

    }

    public setFont(fontname: string, fontstyle: string) {
        if (this.onCanvas) {
            const ctx = this.canvas;
            ctx.font = fontstyle + ' 20px ' + fontname;

        } else {
            const o = this.doc;
            o.setFont(fontname, fontstyle);
        }

    }

    public font(fontname: string, fontstyle: string, fontsize: number) {
        // console.log (fontname);
        if (this.onCanvas) {
            const ctx = this.canvas;
            ctx.font = fontstyle + ' ' + fontsize + 'px' + fontname;
        } else {
            const o = this.doc;
            o.setFont(fontname, fontstyle);
            o.setFontSize(fontsize);
        }
    }

    public setFontSize(setFontSize: number) {
        if (this.onCanvas) {
            const ctx = this.canvas;
            // ctx.font = fontstyle + " 13px " + fontname;
        } else {
            const o = this.doc;
            o.setFontSize(setFontSize);
        }
    }

    public getTextDimensions(txt: string) {
        if (this.onCanvas) {
            const ctx = this.canvas;
            const metrics = ctx.measureText(txt);
            // let fontHeight = metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent;
            const actualHeight = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
            return {
                w: ctx.measureText(txt).width,
                h: actualHeight,
            };

        } else {
            const o = this.doc;
            return o.getTextDimensions(txt);
        }

    }

}

    /*
    // this.celllongated(this.cord, "Calamity fund(5%ofEstimatedIncomefromRegular Source BY 2020)
    // Davao City Philippines", this.style);
    public celllongated(c: any, txt : string, s : any) {
        let o = this.doc;
        o.setFont(s.fontname, s.fontstyle);
        o.setFontSize(s.fontsize);
        const d = o.getTextDimensions(txt);
        if (s.widthstyle === 'auto') {
            this.rectangle(c.x,c.y,
                d.w + s.pLeft + s.pRight,
                d.h + s.pBottom + s.pTop);
            o.text(txt, c.x + s.pLeft , c.y + d.h + s.pTop);
            return {
                totalWidth: d.w + s.pLeft + s.pRight,
                totalHeight: d.h + s.pBottom,
            }
        }
        if (s.widthstyle === 'fixed') {
            const allowableWidth = s.width - s.pLeft - s.pRight;
            // console.log('allowableWidth', allowableWidth);
            // console.log('d.w', d.w);
            if (d.w > allowableWidth) {

                const txtdata = this.setTextArray(txt, allowableWidth ,s.childChar, ' ', o);

                let ht = c.y + d.h + s.pTop;
                let i = 0;
                txtdata.map((m:any) => {
                    o.text(m, c.x + s.pLeft , ht);
                    ht = ht + d.h + s.childSpacing;
                    i++;
                });

                this.rectangle(c.x,c.y,
                    s.width,
                    ((d.h + s.childSpacing) * (i-1)) + s.pBottom + s.pTop + d.h);

            } else {
                this.rectangle(c.x,c.y,
                    s.width,
                    d.h + s.pBottom + s.pTop);
                o.text(txt, c.x + s.pLeft , c.y + d.h + s.pTop);
            }

        }

    }

    // this.cellsimple(this.cord, "Calamity fund (5% of Estimated Income from Regular Source BY 2020)
    // Davao City Philippines", this.style);
    public cellsimple(c: any, txt : string, s : any) {
        let o = this.doc;
        o.setFont(s.fontname, s.fontstyle);
        o.setFontSize(s.fontsize);
        const d = o.getTextDimensions(txt);
        if (s.widthstyle === 'auto') {
            this.rectangle(c.x,c.y,
                d.w + s.pLeft + s.pRight,
                d.h + s.pBottom + s.pTop);
            o.text(txt, c.x + s.pLeft , c.y + d.h + s.pTop);
            return {
                totalWidth: d.w + s.pLeft + s.pRight,
                totalHeight: d.h + s.pBottom,
            }
        }
        if (s.widthstyle === 'fixed') {
            const allowableWidth = s.width - s.pLeft - s.pRight;
            // console.log('allowableWidth', allowableWidth);
            // console.log('d.w', d.w);
            if (d.w > allowableWidth) {
                const txtArr = txt.split(' ');
                const txtdata = [];
                let mytxt = '';
                let space = '';
                let ctr = 1;
                txtArr.map((t:any) => {
                    let p = mytxt;
                    mytxt = mytxt + space + t;
                    let c = o.getTextDimensions(mytxt);

                    if (c.w > allowableWidth){
                        if (ctr == 1) {
                            txtdata.push(p);
                            mytxt = s.childChar + t;
                        } else {

                            txtdata.push(p);
                            mytxt = s.childChar + t;
                        }

                        ctr=0
                    }
                    ctr++;
                    space = ' ';
                });

                txtdata.push(mytxt);
                console.log('Drawing/data', txtdata);

                let ht = c.y + d.h + s.pTop;
                let i = 0;
                txtdata.map((m:any) => {
                    console.log(m);
                    o.text(m, c.x + s.pLeft , ht);
                    ht = ht + d.h + s.childSpacing;
                    i++;
                });

                this.rectangle(c.x,c.y,
                    s.width,
                    ((d.h + s.childSpacing) * (i-1)) + s.pBottom + s.pTop + d.h);

            }

        }




    }

    // this.celltest()
    public celltest() {
        let o = this.doc;
        let txt = "ABCDEFG1234567(),P"
        let d = o.getTextDimensions(txt);
        o.setFont("tahoma", "normal");

        o.setFontSize(10);
        d = o.getTextDimensions(txt);
        o.text(txt + '-' + d.h, 15, 15);
        let ph = d.h;
        o.setFontSize(9);
        d = o.getTextDimensions(txt);
        o.text(txt + '-' + d.h, 15, 15 + ph);

        o.setDrawColor(255, 0, 0); // draw red lines
        this.rectangle(0,0, 5, 216); // total height
    }

    // this.cellbad();
    public cellbad() {
        //https://rawgit.com/MrRio/jsPDF/master/docs/module-cell.html
        let o = this.doc;

        o.text("o.cell(0,0,0,0, 'Hello World', 1 , 'left');", 15, 15)
        console.log('dimension', o.getTextDimensions('Hello World'))
        const d = o.getTextDimensions('Hello World');
        o.cell(15,20,d.w + 2,d.h + 2, 'Hello World', 2);         // cell
        o.cell(15,20,d.w + 2,d.h + 2, 'Hello World', 2);         // cell


    }

    // this.addPage();
    public addPage() {
        let o = this.doc;


        o.addPage({orientation: 'l', format: 'legal'});         // addPage
        this.rectangle(0,0, 100, 100);


    }



    // this.squarebase(0,0);
    public squarebase(x: number, y: number) {
        let o = this.doc;
        let h = 5;
        let w = 5;

        o.line(x, y, x + w, y);         // horizontal line
        o.line(x, y + h, x + w, y + h); // horizontal line

        o.line(x, y, x, y + h);         // vertical line
        o.line(x + w, y, x + w, y + h); // vertical line


    }

    // this.square();
    public square() {
        let o = this.doc;
        let x = 15;
        let y = 15;
        let h = 5;
        let w = 5;

        o.line(x, y, x + w, y);         // horizontal line
        o.line(x, y + h, x + w, y + h); // horizontal line

        o.line(x, y, x, y + h);         // vertical line
        o.line(x + w, y, x + w, y + h); // vertical line


    }
    // this.lineSamples();
    public lineSamples(o: any) {
        o.line(15, 15, 50, 15); // horizontal line
        o.setLineWidth(0.5);
        o.line(15, 20, 50, 20);
        o.setLineWidth(1);
        o.line(15, 25, 50, 25);
        o.setLineWidth(1.5);
        o.line(15, 30, 50, 30);
        o.setDrawColor(255, 0, 0); // draw red lines
        o.setLineWidth(0.1);
        o.line(90, 15, 90, 50); // vertical line
        o.setLineWidth(0.5);
        o.line(95, 15, 95, 50);
        o.setLineWidth(1);
        o.line(105, 15, 100, 50);
        o.setLineWidth(1.5);
        o.line(110, 15, 105, 50);
        console.log('Hit Drawing');
    }
*/

import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
export type VForm = Vue & { resetValidation: () => boolean };

@Component
export default class Project extends Vue {
    public state = this.$store.state;
    public header = {
        atAdd: false,
        atEdit: false,
        atDelete: false,
        onWarning: false,
        onSuccess: true,
        reff: this.$refs,
        notification: '',
        title: '',
        loading: false,
      };
    public form1(): VForm { return this.header.reff.form1 as VForm; }
    public form2(): VForm { return this.header.reff.form2 as VForm; }

    public add() {
        this.header.loading = true;
        const param = {
            parameters:
            {
                key: this.$store.state.apiKey,
                procedurename: 'spSetBarangayProject',
                title: this.header.title,
                brgyID : this.state.current.brgyID,
            },
          };
        this.header.loading = true;
        this.$store.state.api.executePost(this.$store, param, this.header);
    }

    public edit() {
        this.header.loading = true;
        const param = {
            parameters:
            {
                key: this.$store.state.apiKey,
                procedurename: 'spSetBarangayProject_Edit',
                title: this.header.title,
                brgyID : this.state.current.brgyID,
                FPP_id : this.state.current.fppid,
            },
          };

        this.header.loading = true;
        this.$store.state.api.executePost(this.$store, param, this.header);
    }

    public deleteBrgyProj() {
        // console.log('projects', 'deleteBrgyProj');
        this.header.loading = true;
        const param = {
            parameters:
            {
                key: this.$store.state.apiKey,
                procedurename: 'spSetBarangayProject_Delete',
                FPP_id : this.state.current.fppid,
            },
          };
        this.header.loading = true;
        this.$store.state.api.executePost(this.$store, param, this.header);
    }

    public cancel() {

        // this.header.onSuccess = false;

        this.header.title = '';
        this.header.onSuccess = true;
        this.header.loading = false;

        if (this.header.atAdd) { this.form1().resetValidation() ; }
        // (this.$refs.form1 as Vue & { resetValidation: () => boolean }).resetValidation();
        if (this.header.atEdit) { this.form2().resetValidation() ; }

        this.header.atAdd = false;
        this.header.atEdit = false;
        this.header.atDelete = false;
    }
    public editbtn() {
        this.header.title = this.state.current.projSelected ;
    }

}

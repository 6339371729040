import { Vue, Component } from 'vue-property-decorator';
import { Auth } from 'aws-amplify';

@Component
export default class Login extends Vue {
    public error = {message: ''} ;
    public store = this.$store;
    public objHandler = {
        error: '',
        urlparameter: '',
        login: {
            username: '',
            password: '',
            error: '',
        },
        signup: {
            username: '',
            password: '',
            error: '',
        },
     };
    public username = '';
    public email = '';
    public password = '';
    private valid = false;

    public handleSignupSubmit = async (username: string, password: string, email: string, obj: any, store: any) => {
        try {
        const signUpResponse = await Auth.signUp({
            username,
            password,
            attributes: {
              email,
            },
          });

        store.state.router.push('/welcome');
        } catch (err) {
            obj.error = err.message;
        }
    }

    public handleLoginSubmit = async ( obj: any, store: any) => {

        const state = store.state;
        try {
            const user = await Auth.signIn(obj.login.username, obj.login.password);
            store.commit('updateUser', user);
            const procedureName = 'procedurename=brgyuser.spUserVerification';
            const parameters = '&username=' + user.username + '&email=' +  user.attributes.email;
            obj.urlparameter = procedureName + parameters;
            state.api.executeGet(store, obj.urlparameter);

        } catch (err) {
            obj.login.error = err.message;
            state.router.push('/');
        }
    }

}

<template>
<v-row  >
    <v-toolbar>
        <v-toolbar-title>Barangay Budget Completion Status</v-toolbar-title>
    </v-toolbar>
    <v-col  xs = "12" sm = "12" md="12" xl ="12" >
        <v-card class="mr-5 mt-0">
            <v-data-table
            :headers="headers"
            :items="state.spGetBarangayStatus_alter"
            :loading="state.loading"
            :search="search"
            class="elevation-1 reportable"
            @click:row="tableClick"
            >
                <template v-slot:top>
                    <v-toolbar>
                        <v-autocomplete
                            class ="mt-1"
                            v-model="search"
                            :items="barangayItems"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            hide-details
                            @click="reload()"
                        ></v-autocomplete>     
                        <v-btn @click="reload()" class="ml-2" fab x-small> 
                            <v-icon small dark>mdi-reload</v-icon>
                        </v-btn >                                               

                        <v-spacer></v-spacer>
                        <!-- <v-toolbar-items>
                            <v-btn @click="$refs.fileinput.click()" >
                                <span class="hidden-sm-and-down">Upload SIE</span>
                                <v-icon color="green" right>mdi-cloud-upload</v-icon>
                            </v-btn>
                            <input ref="fileinput" @change="upload" type="file" style="display:none"  />                        
                        </v-toolbar-items> -->
                        <v-toolbar-items>
                            <AnnexMenu label="Annex A" @statusChanged="statusChanged($event, 'A')"/>
                            <AnnexMenu label="Annex BCG" @statusChanged="statusChanged($event, 'BCG')"/>
                            <AnnexMenu label="Annex D" @statusChanged="statusChanged($event, 'D')"/>
                            <AnnexMenu label="Annex E" @statusChanged="statusChanged($event, 'E')"/>
                            <AnnexMenu label="Annex F" @statusChanged="statusChanged($event, 'F')"/>
                        </v-toolbar-items>
                    </v-toolbar>    
                </template>
                <template v-slot:item.AnnexA="{item}">
                    <v-chip v-if="item.AnnexA!==''" @click="chipsClick(item, 'annexa')"  :color="getColor(item.AnnexA)" dark>{{ item.AnnexA }}</v-chip>
                </template> 
                <template v-slot:item.AnnexBCG="{item}">
                    <v-chip v-if="item.AnnexBCG!==''" @click="chipsClick(item, 'annexbcg')"   :color="getColor(item.AnnexBCG)" dark>{{ item.AnnexBCG }}</v-chip>
                </template>  
                <template v-slot:item.AnnexD="{item}">
                    <v-chip v-if="item.AnnexD!==''" @click="chipsClick(item, 'annexd')"   :color="getColor(item.AnnexD)" dark>{{ item.AnnexD }}</v-chip>
                </template>    
                <template v-slot:item.AnnexE="{item}">
                    <v-chip v-if="item.AnnexE!==''"  @click="chipsClick(item, 'annexe')"   :color="getColor(item.AnnexE)" dark>{{ item.AnnexE }}</v-chip>
                </template>      
                <template v-slot:item.AnnexF="{item}">
                    <v-chip v-if="item.AnnexF!==''"  @click="chipsClick(item, 'annexf')"   :color="getColor(item.AnnexF)" dark>{{ item.AnnexF }}</v-chip>
                </template>                                                  
            </v-data-table>
        </v-card>
    </v-col>
</v-row>    
</template>

<script>
import AnnexMenu from './AnnexMenu.vue';
import Vue from 'vue';
import { mapState } from 'vuex';
Vue.component('AnnexMenu', AnnexMenu );
export default {

    data() {
        return {
            state: this.$store.state,
            singleSelect: false,
            BarangayStatus: [],
            barangayItems: [],
            search: '',
            budgetYear: this.$route.params.year,
            selected: [],
            headers: [
                {
                text: 'Budget Year',
                align: 'start',
                sortable: true,
                value: 'BudgetYear',
                },
                { text: 'District', sortable: true, value: 'brgy_district' },
                { text: 'Barangay', sortable: true, value: 'brgy_name' },
                { text: 'Annex A', sortable: true, value: 'AnnexA' },
                { text: 'Annex BCG', sortable: true, value: 'AnnexBCG' },
                { text: 'Annex D', sortable: true, value: 'AnnexD' },
                { text: 'Annex E', sortable: true, value: 'AnnexE' },
                { text: 'Annex F', sortable: true, value: 'AnnexF' },
            ],
            handler: {

            },
            spGetBarangayStatus: [
                {
                    BudgetYear: '',
                    brgy_district: '',
                    brgy_name: '',
                    AnnexA:  '',
                    AnnexBCG: '',
                    AnnexD: '',
                    AnnexE: '',
                    AnnexF: '',
                },
            ],
        };
    },

    methods: {
        upload(file) {
            // console.log('hit1');
            const input = this.$refs.fileinput;

            const reader = new FileReader();
            const files = (this.$refs.fileinput).files;
            const store =  this.$store;
            // console.log('hit2');
            files.forEach((o) => {

                reader.onload = (e) => {
                const data = (e.target ).result;
                const bytes = new Uint8Array(data);
                const length = bytes.byteLength;
                let binary = '';

                for (let i = 0; i < length; i++) {
                    binary += String.fromCharCode(bytes[i]);
                }

                // var o = "", l = 0, w = 10240;
                // for(; l < data.byteLength / w; ++l)
                // o+=String.fromCharCode.apply(null,new Uint8Array(data.slice(l*w,l*w+w)));
                // o+=String.fromCharCode.apply(null, new Uint8Array(data.slice(l*w)));



                // console.log('crud/o',o);
                // console.log('crud/binary',binary);
                const fixedData = binary;
                const workbook = XLSX.read(btoa(fixedData), {type: 'base64'});
                const firstSheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[firstSheetName];
                const results = XLSX.utils.sheet_to_json(worksheet);

                // console.log('crud/results b',results);
                // let d = [{values:0}];
                // d =  results.filter((o: any) => o.values !== 'n/a');
                // for(let i = 0 ; i > d.length; i++) {

                // }

                const d =  results.filter((x) => x.value !== '-');
                // console.log('crud/values', d);
                const str = JSON.stringify(results) ;

                const param = {
                    parameters:
                    {
                        key: store.state.apiKey,
                        procedurename: 'sp_Upload_IncomeStatement',
                        filename: (d[1]).value,
                        year: (d[0]).value,
                        brgyName: (d[1]).value,
                        data : str,
                    },
                };
                // console.log('crud/values', param);
                store.commit('loading', true );
                store.state.api.executePost(store, param, store.state.current);

                };

                reader.readAsArrayBuffer(o);

            });

            (this.$refs.fileinput).value = null;
        },
        getColor(status) {
            if (status === 'Ongoing') {
                return 'orange';
            } else if (status === 'Completed') {
                return 'green';
            } else {
                return 'blue';
            }
        },
        statusChanged(event, id) {
            // console.log('BarangayList', event);
            // console.log('BarangayList', id);
            // console.log(this.BarangayStatus);
            // console.log(this.state.spGetBarangayStatus );
            const obj = JSON.parse(JSON.stringify(this.state.spGetBarangayStatus));
            if (id === 'A') {this.state.spGetBarangayStatus_alter = obj.filter((o) => o.AnnexA === event); }
            if (id === 'BCG') {this.state.spGetBarangayStatus_alter = obj.filter((o) => o.AnnexBCG === event); }
            if (id === 'D') {this.state.spGetBarangayStatus_alter = obj.filter((o) => o.AnnexD === event); }
            if (id === 'E') {this.state.spGetBarangayStatus_alter = obj.filter((o) => o.AnnexE === event); }
            if (id === 'F') {this.state.spGetBarangayStatus_alter = obj.filter((o) => o.AnnexF === event); }
            this.search = '';
        },
        chipsClick(value, header) {
            this.state.current.brgy_name = value.brgy_name;
            this.state.current.brgy_district = value.brgy_district;
            this.state.current.brgyID = value.brgy_id;
            this.state.spUserVerification.brgyName = value.brgy_name;
            this.state.spUserVerification.district = value.brgy_district;
            this.state.spUserVerification.brgy_ID = value.brgy_id;
            const o = this.state.navMenuLeft.filter((p) => p.path === header)[0];
            this.$router.push('/' + o.path);

        },
        tableClick(value) {
            // console.log('BarangayList', value);
            this.state.current.brgy_name = value.brgy_name;
            this.state.current.brgy_district = value.brgy_district;
            this.state.current.brgyID = value.brgy_id;
            this.state.spUserVerification.brgyName = value.brgy_name;
            this.state.spUserVerification.district = value.brgy_district;
            this.state.spUserVerification.brgy_ID = value.brgy_id;
        },
        reload() {
            this.state.spGetBarangayStatus_alter = JSON.parse(JSON.stringify(this.state.spGetBarangayStatus));
            this.search = '';
        },
        callBack() {
            const searchItems = [];
            const lookup = {};

            const obj = this.state.spGetBarangayStatus;
            obj.map( (o) => {
                let name = o.brgy_district;
                if (!(name in lookup)) {
                    lookup[name] = 1;
                    searchItems.push(name);
                }
                name = o.brgy_name;
                if (!(name in lookup)) {
                    lookup[name] = 1;
                    searchItems.push(name);
                }
            });

            searchItems.push('Ongoing');
            searchItems.push('Completed');
            searchItems.push('Approved');
            searchItems.push('2018');
            searchItems.push(' ');
            this.barangayItems = searchItems;
            this.state.spGetBarangayStatus_alter = JSON.parse(JSON.stringify(this.state.spGetBarangayStatus));

            // console.log('BarangayList/obj', this.barangayItems ) // ['a', 'b', 'c'];
        },
        mountData() {
            this.state.current.header = 'BarangayList';
            this.state.current.budgetYear = this.budgetYear;
            this.state.spGetBarangayStatus_alter = JSON.parse(JSON.stringify(this.spGetBarangayStatus));
            this.state.loading = true;
            this.$store.state.api.executeGet(this.$store,
                'procedurename=spGetBarangayStatus&budgetYear=' + this.state.current.budgetYear ,
                this, true);
        },
    },

    mounted() {
        // console.log('BarangayList/budgetYear', this.budgetYear);
        // console.log('BarangayList/route.params',this.$route.params);
        // console.log('BarangayList/route.params.year',this.$route.params.year);
        // alert('hit');
        this.mountData();
        // console.log('Barangalist/mapstate',mapState(['notLogin']) )

    },
    watch: {
        spGetBarangayStatus(newValue, oldValue) {
            // alert('BarangayList altered');
            this.mountData();
        },
        deep: true,
    },
};
</script>
<style lang="scss" src="./barangaylist.scss" scoped></style>


import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import Inputs from '../Inputs.vue';
@Component({
    components: {
        Inputs,
    },
})
export default class DataCom extends Vue {
    @Prop(Array) public headers: [] | undefined;
    @Prop(String) public title: '' | undefined;
    @Prop(String) public editModeCrud: false | undefined;
    public state = this.$store.state;
    public items = ['item1', 'item2'];
    public get dataitems() {
        return this.state.titleposition.map((a: any) => a.position);
    }

    public save() {
        this.state.dialog.save = false;
        this.$emit('save', this.headers);
        // this.clear();
    }
    public clear() {

        // console.log('datacom', 'clear');
        if (this.headers != null) {
            this.headers.map((h: any) => {
                h.model = null;
            });
        }
        this.state.dialog.save = false;
    }

    // @Watch ('state.dialog')
    // public onHeaderModel( val: any, oldVal: any) {
    //     // console.log('datacom hit' );
    // }

}

import { Vue, Component, Prop } from 'vue-property-decorator';
import DataCom from '../DataCom.vue';
@Component({
    components: {
        DataCom,
    },
})
export default class Reports extends Vue {
    @Prop(Array) public headers: [] | undefined;
    @Prop(Array) public items: [] | undefined;
    @Prop(String) public title: '' | undefined;
    public loading = true;
    public state = this.$store.state;

    public mounted() {
        this.$store.commit('loading', true );
        this.$store.state.api.executeGet(this.$store,
            'procedurename=Get_AnnexAReport&brgy_id=' + this.state.current.brgyID +
            '&year=' +  this.state.current.budgetYear);

    }
    public get getHeader() {
        const mheader = this.headers ? this.headers : [];
        return (this.state.current.status === 0) ? this.headers :
        mheader.filter((a: any) => a.value !== 'action' );
    }
    public indent(lvl: any) {
        return '_'.repeat(lvl);
    }
    public editItem(item: any) {
        const mheader = this.headers ? this.headers : [];
        const obj  = mheader.filter((h: any) => h.visible);

        obj.map((o: any) => {
            return o.model = item[o.value];
        });
        // console.log('Reports/obj', obj);
        // console.log('Reports/item', item);
        // console.log('Reports/item', this.headers);
        this.state.dialog.save = true;
    }
    public deleteItem(item: any) {
        this.state.dialog.delete = true;

    }
    public save() {
        this.$emit('save', this.headers);
    }
    public deleteExecute() {
        // console.log('reports/headers',this.headers)
        this.$emit('deleteExecute', this.headers);
    }
}


import { Vue, Component, Watch } from 'vue-property-decorator';
import Accordion from './Accordion.vue';


@Component({
    components: {
        Accordion,
    },
  })
export default class LeftNav extends Vue {
    public state = this.$store.state;
    public mini = false;
    public panel = [true, true, true];
    public searhItems = [];
    public loading = false;
    public searchModel = '';
    public searchHistory = [{}];

    public chip4 = true;
    @Watch ('state.current.brgy_name')
    public stateCurrentBrgy_name_Changed( val: any, oldVal: any) {
        this.searchModel = val;
    }
    @Watch ('searchModel')
    public searchModelChanged( val: any, oldVal: any) {
        const data = this.state.spGetBarangayList.filter((a: any) =>
            a.brgy_name === val)[0];
        // Search first if it is already loaded then remove then load
        const shdata = this.searchHistory.filter((a: any) => a.brgy_id === data.brgy_id);
        if (shdata.length === 0) {
            this.searchHistory.push(data);
        }
        // if greater than 10 then remove the first one
        if (this.searchHistory.length > 9) {
            delete this.searchHistory[0];

        }
        // validate that its okay
        this.searchHistory = this.searchHistory.filter((a: any) => a.brgy_id !== 0);
        // console.log("left nav",this.searchHistory  )
        this.state.current.captain = data.captain;
        this.state.current.secretary = data.secretary;
        this.state.current.treasurer = data.treasurer;

        this.searchReload(data);

    }
    public searchitemClose(item: any) {
        this.searchHistory = this.searchHistory.filter((a: any) => a.brgy_id !== item.brgy_id);


    }
    public searchReload(item: any) {
        this.searchHistory = this.searchHistory.map( (a: any) => {
            a.color = 'cyan';
            return a;
        } );
        item.color = 'purple lighten-2';
        this.state.current.brgyID = item.brgy_id;
        this.state.current.brgy_name = item.brgy_name;
        this.state.current.brgy_district = item.brgy_district;
        this.state.current.captain = item.captain;
        this.state.current.secretary = item.secretary;
        this.state.current.treasurer = item.treasurer;
        this.reload();
    }
    public searchitemLoad(item: any) {
        this.searchModel = item.brgy_name;
    }
    public navclicked(item: any) {
        // console.log('left-navs/path', path);
        // alert(item.group);
        let path = item.path;
        if (item.group === 'year') {
            if (this.state.current.budgetYear  !== item.id) {
                this.state.current.budgetYear = item.id;
                path = this.state.current.header;
                switch (this.state.current.header) {
                    case 'BarangayList':
                        path =  item.path;
                        this.state.loading = true;
                        this.$store.state.api.executeGet(this.$store,
                            'procedurename=spGetBarangayStatus&budgetYear=' + this.state.current.budgetYear);
                        if (this.$route.path !== '/' + path) { this.$router.push( path); }
                        break;
                    case 'AnnexA':
                        // this.$store.state.current.header = 'AnnexA';
                        // this.$store.state.current.status = this.$store.state.status.annexA;
                        this.$store.commit('loading', true );
                        this.$store.state.api.executeGet(this.$store,
                            'procedurename=Get_AnnexAReport&brgy_id=' + this.state.current.brgyID +
                            '&year=' +  this.state.current.budgetYear);
                        break;
                    case 'AnnexBCG':
                        // this.$store.state.current.header = 'AnnexBCG';
                        // this.$store.state.current.status = this.$store.state.status.annexBCG;
                        this.$store.commit('loading', true );
                        this.$store.state.api.executeGet(this.$store,
                            'procedurename=annexBCG&brgyID=' + this.state.current.brgyID +
                            '&Budget_year=' +  this.state.current.budgetYear +
                            '&code=' +  this.state.current.code );
                        break;
                    case 'AnnexD':
                        // this.$store.state.current.header = 'AnnexD';
                        // this.$store.state.current.status = this.$store.state.status.annexD;
                        this.$store.commit('loading', true );
                        this.$store.state.api.executeGet(this.$store,
                            'procedurename=spGet_AnnexDReport&brgy_id=' + this.state.current.brgyID +
                            '&year=' +  this.state.current.budgetYear);
                        break;
                    case 'AnnexE':
                        // this.$store.state.current.header = 'AnnexE';
                        // this.$store.state.current.status = this.$store.state.status.annexE;
                        this.$store.commit('loading', true );
                        this.$store.state.api.executeGet(this.$store,
                            'procedurename=spGet_AnnexEReport&brgy_id=' + this.state.current.brgyID +
                            '&year=' +  this.state.current.budgetYear);
                        break;
                    case 'AnnexF':
                        // this.$store.state.current.header = 'AnnexF';
                        // this.$store.state.current.status = this.$store.state.status.annexF;
                        this.$store.commit('loading', true );
                        this.$store.state.api.executeGet(this.$store,
                            'procedurename=spGet_AnnexFReport&brgy_id=' + this.state.current.brgyID +
                            '&year=' +  this.state.current.budgetYear);
                        break;
                    default:
                        // code block
                    }


            }
        } else {
            if (this.state.current.brgyID !== '0') {
                if (this.$route.path !== '/' + path) { this.$router.push('/' + path); }
            } else {
                alert ('Search or Select first a baranagay');
            }


        }



        // this.$router.push(path);
    }
    public keymonitor(key: any) {
        return 0;
    }
    public mounted() {
        this.loading = true;
        this.searchHistory = [];
        this.$store.state.api.executeGet(this.$store,
            'procedurename=spGetBarangayList', this);
    }
    public reload() {
        switch (this.state.current.header) {
            case 'AnnexA':
                this.$store.commit('loading', true );
                this.$store.state.api.executeGet(this.$store,
                    'procedurename=Get_AnnexAReport&brgy_id=' + this.state.current.brgyID +
                    '&year=' +  this.state.current.budgetYear);
                break;
            case 'AnnexBCG':
                this.$store.commit('loading', true );
                this.$store.state.api.executeGet(this.$store,
                    'procedurename=annexBCG&brgyID=' + this.state.current.brgyID +
                    '&Budget_year=' +  this.state.current.budgetYear +
                    '&code=' +  this.state.current.code );
                break;
            case 'AnnexD':
                this.$store.commit('loading', true );
                this.$store.state.api.executeGet(this.$store,
                    'procedurename=spGet_AnnexDReport&brgy_id=' + this.state.current.brgyID +
                    '&year=' +  this.state.current.budgetYear);
                break;
            case 'AnnexE':
                this.$store.commit('loading', true );
                this.$store.state.api.executeGet(this.$store,
                    'procedurename=spGet_AnnexEReport&brgy_id=' + this.state.current.brgyID +
                    '&year=' +  this.state.current.budgetYear);
                break;
            case 'AnnexF':
                this.$store.commit('loading', true );
                this.$store.state.api.executeGet(this.$store,
                    'procedurename=spGet_AnnexFReport&brgy_id=' + this.state.current.brgyID +
                    '&year=' +  this.state.current.budgetYear);
                break;
            default:
                break;
            }
    }
    public navclickedYear(year: any) {

        if (this.state.current.header === 'BarangayList') {
            const mpath =  (this.state.current.header === 'BarangayList') ?  '/'
                            + this.state.current.header + '/' + year : '/' + this.state.current.header  ;
            const mgroup =  (this.state.current.header === 'BarangayList') ?  'year' : 'forms'  ;
            const item =   { text: 'Year ' + year, icon: '', id: year , path: mpath, group: mgroup};
            // console.log('left-nav/item', item);
            this.navclicked(item);
        } else {
            this.state.current.budgetYear = year;
            this.reload();
        }


    }
}



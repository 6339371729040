













import Vue from 'vue';
export default Vue.extend({
    data() {
        return {
            // By creating the provider in the data property, it becomes reactive,
            // so child components will update when `context` changes.
            provider: {
                // This is the CanvasRenderingContext that children will draw to.
                context: null,
            },
            state : this.$store.state,
        };
    },

    mounted() {

        this.provider.context  = (this.$refs.mycanvas as any).getContext('2d');
        const ctx = (this.provider.context !== null) ? this.provider.context : '' ;
        this.state.Draw.myCanvas = ctx;
        // (ctx as any).moveTo(0, 0);
        // (ctx as any).lineTo(200, 100);
        // (ctx as any).stroke();
    },
});

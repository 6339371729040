import { Vue, Component } from 'vue-property-decorator';
import DataCom from '../components/DataCom.vue';
import Report from '../components/Report.vue';
import Status from '../components/Status.vue';

@Component({
    components: {
        DataCom,
        Report,
        Status,
    },
})
export default class AnnexA extends Vue {
    public spUserVerification = this.$store.state.spUserVerification;
    public state = this.$store.state;
    public mounted() {

        this.state.reportDetails = {
            name: 'Annex A',
            formNumber: '',
            title: 'FY ' + this.state.current.budgetYear + ' Annual Investment Program (AIP) ',
            subtitle: 'By Program/ Project / Activity Sector',
            cy: 'CY-' + this.state.current.budgetYear,
            barangay: 'Barangay ' +
                    this.state.current.brgy_name.toLowerCase().replace(/^\w/, (c: any) => c.toUpperCase()),
            city: 'City of Davao',
            classification: 'GENERAL FUND',
            capitan: this.state.current.captain,
            secretary: this.state.current.secretary,
            treasurer: this.state.current.treasurer,
          };


        this.$store.state.current.header = 'AnnexA';
        this.$store.state.current.status = this.$store.state.status.annexA;
        this.$store.commit('loading', true );
        this.$store.state.api.executeGet(this.$store,
            'procedurename=Get_AnnexAReport&brgy_id=' + this.state.current.brgyID +
            '&year=' +  this.state.current.budgetYear);

    }
    public clear() {
        (this.$refs.dataCom as Vue & { clear: () => boolean }).clear();
        // this.$refs.dataCom.clear();
    }

    public deleteRecord(headers: any) {
        const field = (fieldname: string) => headers.filter((a: any) => a.value === fieldname)[0].model;
        const param = {
            parameters:
            {
                key: this.$store.state.apiKey,
                procedurename: 'spdeleteAnnexAdetails',
                AIP_detailsid: field('AIP_detailsid'),
            },
        };

        this.$store.commit('loading', true );
        this.$store.state.api.executePost(this.$store, param, this);
    }
    public save(headers: any) {
        // console.log('annexa', headers);
        const field = (fieldname: string) => headers.filter((a: any) => a.value === fieldname)[0].model;
        // console.log('annexa/AIP_detailsid', field('AIP_detailsid'));
        // console.log('annexa/', field('AIP_code'));
        // console.log('annexa/', field('Proj_name'));
        // console.log('annexa/', field('start_date'));
        // console.log('annexa/', field('completion_date'));
        // console.log('annexa/', field('AIP_detailsid'));
        const param = {
            parameters:
            {
                key: this.$store.state.apiKey,
                procedurename: 'spAnnexAUpdate',
                brgy_id: this.state.current.brgyID,
                year: this.state.current.budgetYear,
                AIP_detailsid: field('AIP_detailsid'),
                AIP_code: field('AIP_code'),
                Proj_name: field('Proj_name'),
                start_date: field('start_date'),
                completion_date: field('completion_date'),
                expected_outputs: field('expected_outputs'),
                funding_source: field('funding_source'),
                amount_ps: field('amount_ps'),
                amount_mooe: field('amount_mooe'),
                amount_co: field('amount_co'),
            },
        };
        // console.log('annexa/param', param);
        this.$store.commit('loading', true );
        this.$store.state.api.executePost(this.$store, param, this);
    }
}

